import React, { useEffect, useState, Fragment } from "react";
import { exportToExcel } from "../common/Services/Export";
import { Select } from "antd";

const { Option } = Select;

export const TransactionList = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [selectedType, setSelectedType] = useState(""); 
  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
    }, 3000);
  }, []);

  const filteredList = (obj) => {
    
  };

  const handleType = value => {
    console.log("--- Selected Type ---", value);
    if (value == 'All') {
        setSelectedType("")
    } else {
        setSelectedType(value);
    }
  }

  const handleFilter = () => {
    let obj = {};
    if (selectedType) {
      obj.type = selectedType;
    }
    filteredList(obj);
  };

  const handleClear = () => {
    setSelectedType("");
  };

  return (
    <Fragment>
      {isLoading && <div id="preloader"></div>}
      <div className="page-content-wrapper">
        <div className="page-content">
          <div className="page-bar">
            <div className="page-title-breadcrumb">
              <div className=" pull-left">
                <div className="page-title">All Transactions</div>
              </div>
              <ol className="breadcrumb page-breadcrumb pull-right">
                <li>
                  <i className="fa fa-home"></i>&nbsp;
                  <a className="parent-item" href="index.html">
                    Home
                  </a>
                  &nbsp;<i className="fa fa-angle-right"></i>
                </li>
                <li>
                  <a className="parent-item" href="">
                    Transactions
                  </a>
                  &nbsp;<i className="fa fa-angle-right"></i>
                </li>
                <li className="active">All Transactions</li>
              </ol>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">
              <div className="card card-box">
                <div className="card-head">
                  <header>All Transactions</header>
                  <div className="tools">
                    <a
                      className="fa fa-repeat btn-color box-refresh"
                      href="javascript:;"
                    ></a>
                    <a
                      className="t-collapse btn-color fa fa-chevron-down"
                      href="javascript:;"
                    ></a>
                    <a
                      className="t-close btn-color fa fa-times"
                      href="javascript:;"
                    ></a>
                  </div>
                </div>

                <div className="card-body">
                  <div className="row p-b-20">
                    <div className="col-md-6 col-sm-6 col-6">
                      <Select
                        defaultValue="All"
                        style={{ width: 200 }}
                        onChange={handleType}
                      >
                        <Option value="1">Order Related Transactions </Option>
                        <Option value="2">Commission Related Transactions</Option>
                      </Select>
                      <button
                        type="button"
                        className="btn deepPink-bgcolor"
                        style={{
                          marginLeft: "5px",
                          marginRight: "5px",
                          marginBottom: "5px",
                        }}
                        onClick={handleFilter}
                      >
                        Search
                      </button>
                      <button
                        type="button"
                        className="btn btn-default"
                        style={{ marginBottom: "5px" }}
                        onClick={handleClear}
                      >
                        Clear
                      </button>
                    </div>
                    <div className="col-md-6 col-sm-6 col-6">
                    <div className="btn-group pull-right">
                        <a
                          className="btn deepPink-bgcolor  btn-outline dropdown-toggle"
                          data-toggle="dropdown"
                        >
                          Tools
                          <i className="fa fa-angle-down"></i>
                        </a>
                        <ul className="dropdown-menu pull-right">
                          <li>
                            <button style={{ marginLeft: "16px" }}>
                              <i className="fa fa-print"></i> Print{" "}
                            </button>
                          </li>
                          <li>
                            <button style={{ marginLeft: "16px" }}>
                              <i className="fa fa-file-pdf-o"></i> Save as PDF{" "}
                            </button>
                          </li>
                          <li>
                            <button
                              style={{ marginLeft: "16px" }}
                              onClick={() =>
                                exportToExcel("example4", "All Transactions")
                              }
                            >
                              <i className="fa fa-file-excel-o"></i> Export to
                              Excel{" "}
                            </button>
                          </li>
                        </ul>
                      </div>
                    </div>

                    <div className="table-scrollable">
                      <table
                        className="table table-hover table-checkable order-column full-width"
                        id="example4"
                      >
                        <thead>
                          <tr>
                            <th className="center"> Id</th>
                            <th className="center"> Transaction </th>
                            <th className="center"> Description </th>
                            <th className="center"> Start Time </th>
                            <th className="center"> End Time </th>
                            <th className="center"> Type </th>
                            <th className="center"> Status </th>
                            <th className="center"> Action </th>
                          </tr>
                        </thead>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
