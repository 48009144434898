import React, { useEffect, useState } from "react";
import { getAllOrders, updateOrderStatus } from "../../api/order/OrderProfile";
import swal from "sweetalert";
import { useHistory } from "react-router";

export const EditOrdersComponent = (props) => {
  const history = useHistory();
  const [orders, setOrders] = useState([]);

  useEffect(() => {
    getAllOrders().then((res) => {
      handlePendingOrders(res.data.orders);
    });
  }, []);

  const handlePendingOrders = (data) => {
    let newArr = [];
    data.forEach((element) => {
      if (element.status != "Delivered") {
        newArr.push(element);
      }
    });
    setOrders(newArr);
  };

  const renderStatus = (status) => {
    switch (status) {
      case "Pending":
        return <span className="label label-sm label-warning">Pending</span>;
      case "Accepted":
        return (
          <span className="label label-sm label-danger">
            Accepted by Vendor
          </span>
        );
      case "Delivered":
        return <span className="label label-sm label-success">Delivered</span>;
      case "Delivering":
        return (
          <span className="label label-sm label-warning">Out for Delivery</span>
        );

      default:
        break;
    }
  };

  const renderBackgroundColor = (status) => {
    switch (status) {
      case "Pending":
        return "label-warning";
      case "Accepted":
        return "bg-b-danger";
      case "Delivered":
        return "label-success";
      case "Delivering":
        return "bg-b-orange";

      default:
        break;
    }
  };

  const renderStatusDiv = (status) => {
    switch (status) {
      case "Pending":
        return "label-warning";
      case "Accepted":
        return "label-danger";
      case "Delivered":
        return "label-success";
      case "Delivering":
        return "label-warning";

      default:
        break;
    }
  };

  const handleStatus = (id, status) => {
    let obj = {};
    if (status == "Pending") {
      obj.status = "Accepted";
    } else if (status == "Accepted") {
      obj.status = "Delivering";
    } else if (status == "Delivering") {
      obj.status = "Delivered";
    }
    // swal({
    //   title: "Are you sure?",
    //   text: "Once Updated, you will not be able to recover!",
    //   icon: "warning",
    //   buttons: true,
    //   dangerMode: true,
    // }).then((willDelete) => {
    //   if (willDelete) {
    //     updateOrderStatus(id, obj).then((res) => {
    //       if (res.data.status == "success") {
    //         swal(`Successfully Updated from ${status} to ${obj.status} !`, {
    //           icon: "success",
    //         });
    //         getAllOrders().then((res) => {
    //           handlePendingOrders(res.data.orders);
    //         });
    //       }
    //     });
    //   } else {
    //     swal("Process Terminated!");
    //   }
    // });
    history.push({
      pathname:  "/order-detail",
      state: {
        id: id,
        obj: obj,
        currentStatus: status 
      } 
   });
  };

  return (
    <div className="page-content-wrapper">
      <div className="page-content">
        <div className="page-bar">
          <div className="page-title-breadcrumb">
            <div className=" pull-left">
              <div className="page-title">Pending Orders</div>
            </div>
            <ol className="breadcrumb page-breadcrumb pull-right">
              <li>
                <i className="fa fa-home"></i>&nbsp;
                <a className="parent-item" href="index.html">
                  Home
                </a>
                &nbsp;<i className="fa fa-angle-right"></i>
              </li>
              <li>
                <a className="parent-item" href="">
                  Apps
                </a>
                &nbsp;<i className="fa fa-angle-right"></i>
              </li>
              <li className="active">Pending Orders</li>
            </ol>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12">
            <div className="card-box ">
              <div className="card-head">
                <header>Pending Orders</header>
                <div className="tools">
                  <a
                    className="fa fa-repeat btn-color box-refresh"
                    href="javascript:;"
                  ></a>
                  <a
                    className="t-collapse btn-color fa fa-chevron-down"
                    href="javascript:;"
                  ></a>
                  <a
                    className="t-close btn-color fa fa-times"
                    href="javascript:;"
                  ></a>
                </div>
              </div>
              <div className="card-body ">
                <div className="tab-pane" id="tab2">
                  <div className="row">
                    {orders.map((item, key) => {
                      return (
                        <div className="col-md-4" key={key}>
                          <div className="card">
                            <div className="m-b-20">
                              <div className="doctor-profile">
                                <div
                                  className={`profile-header ${
                                    item.status
                                      ? renderBackgroundColor(item.status)
                                      : ""
                                  }`}
                                >
                                  <div className="user-name">
                                    {item?.customer?.fullName}
                                  </div>
                                  {/* <div
                                    className={`label label-sm ${
                                      item.status
                                        ? renderStatusDiv(item.status)
                                        : ""
                                    }`}>
                                    {item.status}
                                  </div> */}
                                </div>
                                <img
                                  src="assets/img/user/usrbig1.jpg"
                                  className="user-img"
                                  alt=""
                                />
                                <div>KWD {item.amount.toFixed(2)}</div>
                                <p>{item.address}</p>
                                <div>
                                  <p>
                                    <i className="fa fa-phone"></i>
                                    <a href="tel:(123)456-7890">
                                      {item.customer.phone_number}
                                    </a>
                                  </p>
                                </div>
                                <div>
                                  <span
                                    onClick={() =>
                                      handleStatus(item._id, item.status)
                                    }
                                    style={{ cursor: "pointer" }}
                                  >
                                    {renderStatus(item.status)}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
