import React, { Component } from "react";
import { Footer } from "../components/common/Footer";
import { PageHeader } from "../components/common/PageHeader";
import SideBar from "../components/common/SideBar";
import { AllOrdersComponent } from "../components/Orders/AllOrders";

class Orders extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <div className="page-header-fixed sidemenu-closed-hidelogo page-content-white page-md header-white dark-sidebar-color logo-dark">
        <div className="page-wrapper">
          <PageHeader />
          <div className="page-container">
            <SideBar activeMenu='4.0' />
            <AllOrdersComponent />
          </div>
          <Footer />
        </div>
      </div>
    );
  }
}

export default Orders;
