import axios from "axios";

export const getAllProducts = (params = {}) => {
  return axios.get(
    `${process.env.REACT_APP_API_URL}/vendor/products`,
    {
      headers: {
        Authorization: localStorage.access_token,
      },
    },
    params
  );
};

export const createProduct = (data) => {
  return axios.post(`${process.env.REACT_APP_API_URL}/vendor/product`, data, {
    headers: {
      Authorization: localStorage.access_token,
    },
  });
};

export const deleteProduct = (id) => {
  return axios.delete(`${process.env.REACT_APP_API_URL}/vendor/product/${id}`, {
    headers: {
      Authorization: localStorage.access_token,
    },
  });
};

export const editProduct = (id, data) => {
  return axios.patch(
    `${process.env.REACT_APP_API_URL}/vendor/product/${id}`,
    data,
    {
      headers: {
        Authorization: localStorage.access_token,
      },
    }
  );
};

export const getProductById = (id) => {
  return axios.get(`${process.env.REACT_APP_API_URL}/vendor/product/${id}`, {
    headers: {
      Authorization: localStorage.access_token,
    },
  });
};

export const getCategories = () => {
  return axios.get(`${process.env.REACT_APP_API_URL}/vendor/category`, {
    headers: {
      Authorization: localStorage.access_token,
    },
  });
};

export const addToSellProduct = (id, data) => {
  return axios.patch(
    `${process.env.REACT_APP_API_URL}/vendor/product/${id}`,
    {},
    {
      headers: {
        Authorization: localStorage.access_token,
      },
    }
  );
};
