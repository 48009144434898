import React, { useEffect, useState, Fragment } from "react";
import { Select, Upload } from "antd";
import { useHistory } from "react-router";
import { createProduct, getCategories } from "../../api/product/ProductProfile";
import { FileUpload } from "./FileUpload";
import TagsInput from "react-tagsinput";
import Dropzone from "react-dropzone-uploader";
import swal from "sweetalert";
import { EditorState } from "draft-js";
import { Checkbox } from "antd";
import "react-tagsinput/react-tagsinput.css";
import "react-dropzone-uploader/dist/styles.css";
import "antd/lib/upload/style/index.css";
import "antd/lib/modal/style/index.css";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

const { Option } = Select;
const productArr = ["test1", "test2", "test3"];
const prodTypeArr = ["1", "2", "3", "4", "5", "6", "7", "8"];
const KeyCodes = {
  comma: 188,
  enter: 13,
};
const delimiters = [KeyCodes.comma, KeyCodes.enter];
const { Dragger } = Upload;

export const AddProduct = (prop) => {
  const history = useHistory();
  const [product, setProduct] = useState("");
  const [productType, setProductType] = useState("");
  const [productName, setProductName] = useState("");
  const [productEmail, setProductEmail] = useState("");
  const [productDescription, setProductDiscription] = useState();
  const [sizeTags, setSizeTags] = useState([]);
  const [colortags, setColorTags] = useState([]);
  const [materialTags, setMaterials] = useState([]);
  const [price, setPrice] = useState();
  const [fileList, setFileList] = useState([]);
  const [arFileList, setArFileList] = useState([]);
  const [arChecked, setArChecked] = useState(false);
  const [categories, setCategories] = useState([]);
  const [subCategoty, setSubCategory] = useState([]);
  const [stock, setStock] = useState("");
  const [shippingFee, setShippingFee] = useState();
  const [visible, setVisible] = useState(false);
  const [message, setMessage] = useState("");
  const [error, setError] = useState(false);
  const [editorState, setEditor] = useState(EditorState.createEmpty());
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getCategories().then((res) => {
      if (res.data.status == "success") {
        setCategories(res.data.categories);
      }
    });
  }, []);

  const handleValidation = (data) => {
    let errors = {};
    if (!data.name) {
      errors.name = "Name Required !";
    } else if (!data.description) {
      errors.email = "Description Required !";
    } else if (!data.category) {
      errors.password = "Category Required !";
    } else if (!data.colors) {
      errors.colors = "Color Required !";
    } else if (!data.images) {
      errors.images = "Images Required !";
    } else if (!data.stock) {
      errors.stock = "Stock Required !";
    } else if (!data.amount) {
      errors.amount = "Price Required !";
    }

    return errors;
  };

  const handleSubmit = () => {
    setLoading(true);
    let obj = {
      name: productName,
      description: productDescription,
      category: product,
      sub_category: productType,
      colors: colortags,
      material_type: materialTags,
      images: fileList,
      stock: stock,
      amount: price,
      shippingFee: shippingFee,
      ar_enabled: arChecked,
      ar_images: arFileList,
      sizes: sizeTags
    };

    const errors = handleValidation(obj);

    if (!Object.keys(errors).length) {
      swal({
        title: "Are you sure?",
        text: "",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          createProduct(obj).then((res) => {
            if (res.data.status == "success") {
              setLoading(false);
              setError(false);
              setMessage("Successfully submitted to admin ...");
              history.push({
                pathname: "/products",
              });
              swal("Successfully Submitted!", "", "success");
            } else {
              setLoading(false);
              setError(true);
              setMessage("Something went wrong!");
            }
          });
          setTimeout(() => {
            setVisible(false);
            setMessage("");
          }, 4000);
        } else {
          swal("Process Terminated!");
        }
      });
    } else {
      swal("All Fields Are Required !", "", "error");
    }
  };

  const handleCancel = () => {};

  const handleSizeTag = (tag) => {
    setSizeTags(tag);
  };

  const handleColorTag = (tag) => {
    setColorTags(tag);
  };

  const handleMaterial = (tag) => {
    setMaterials(tag);
  };

  const getUploadParams = ({ meta }) => {
    return { url: "https://httpbin.org/post" };
  };

  const getUploadParamsAR = ({ meta }) => {
    return { url: "https://httpbin.org/post" };
  };

  // called every time a file's `status` changes
  const handleChangeStatus = ({ meta, file }, status) => {
    if (status == "done") {
      FileUpload(file, (res) => {
        fileList.push(res.location);
        setFileList(fileList);
      });
    }
  };

  const handleChangeStatusAR = ({ meta, file }, status) => {
    if (status == "done") {
      FileUpload(file, (res) => {
        arFileList.push(res.location);
        setArFileList(arFileList);
      });
    }
  };

  const handleCategory = (e) => {
    let newArray = categories.filter(function (item) {
      return item._id == e;
    });
    setProduct(e);
    setSubCategory(newArray[0].sub_category);
  };

  const onEditorStateChange = () => {
    console.log("editorState", editorState.getCurrentContent().getPlainText());
    setEditor(editorState.getCurrentContent().getPlainText());
  };

  const handleAr = (e) => {
    setArChecked(e.target.checked);
  };

  return (
    <div className="page-content-wrapper">
      <div className="page-content">
        <div className="page-bar">
          <div className="page-title-breadcrumb">
            <div className=" pull-left">
              <div className="page-title">Add New Product</div>
            </div>
            <ol className="breadcrumb page-breadcrumb pull-right">
              <li>
                <i className="fa fa-home"></i>&nbsp;
                <a className="parent-item" href="index.html">
                  Home
                </a>
                &nbsp;<i className="fa fa-angle-right"></i>
              </li>
              <li>
                <a className="parent-item" href="">
                  Products
                </a>
                &nbsp;<i className="fa fa-angle-right"></i>
              </li>
              <li className="active">Add New Product</li>
            </ol>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12">
            <div className="card-box">
              <div className="card-head">
                <header>Add New Product</header>
                <button
                  id="panel-button"
                  className="mdl-button mdl-js-button mdl-button--icon pull-right"
                  data-upgraded=",MaterialButton"
                >
                  <i className="material-icons">more_vert</i>
                </button>
                <ul
                  className="mdl-menu mdl-menu--bottom-right mdl-js-menu mdl-js-ripple-effect"
                  data-mdl-for="panel-button"
                >
                  <li className="mdl-menu__item">
                    <i className="material-icons">assistant_photo</i>Action
                  </li>
                  <li className="mdl-menu__item">
                    <i className="material-icons">print</i>Another action
                  </li>
                  <li className="mdl-menu__item">
                    <i className="material-icons">favorite</i>Something else
                    here
                  </li>
                </ul>
              </div>
              <div className="card-body row">
                <div className="col-lg-6 p-t-20">
                  <label for="sample2" className="mdl-textfield__label">
                    Choose Category
                  </label>
                  <div className="mdl-textfield mdl-js-textfield mdl-textfield--floating-label getmdl-select getmdl-select__fix-height txt-full-width">
                    <select
                      onChange={(e) => handleCategory(e.target.value)}
                      className="mdl-textfield__input"
                    >
                      <option />
                      {categories.map((item, key) => {
                        return (
                          <option
                            value={item._id}
                            key={key}
                            className="mdl-menu__item"
                          >
                            {item.name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
                <div className="col-lg-6 p-t-20">
                  <label className="mdl-textfield__label">Seller Email</label>
                  <div className="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width">
                    <input
                      className="mdl-textfield__input"
                      type="email"
                      id="txtemail"
                      onChange={(e) => setProductEmail(e.target.value)}
                    />
                    <span className="mdl-textfield__error">
                      Enter Valid Email Address!
                    </span>
                  </div>
                </div>
                <div className="col-lg-6 p-t-20">
                  <label className="mdl-textfield__label">Product Name</label>
                  <div className="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width">
                    <input
                      className="mdl-textfield__input"
                      type="text"
                      id="txtLasttName"
                      onChange={(e) => setProductName(e.target.value)}
                    />
                  </div>
                </div>
                <div className="col-lg-6 p-t-20">
                  <label for="list2" className="mdl-textfield__label">
                    Product Type
                  </label>
                  <div className="mdl-textfield mdl-js-textfield mdl-textfield--floating-label getmdl-select getmdl-select__fix-height txt-full-width">
                    <select
                      onChange={(e) => setProductType(e.target.value)}
                      className="mdl-textfield__input"
                    >
                      <option />
                      {subCategoty.map((item, key) => {
                        return (
                          <option key={key} className="mdl-menu__item">
                            {item}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
                <div className="col-lg-12 p-t-20">
                  <label className="mdl-textfield__label" for="text7">
                    Product Description
                  </label>
                  {/* <div className="mdl-textfield mdl-js-textfield txt-full-width">
                    <Editor
                      editorState={editorState}
                      toolbarClassName="toolbarClassName"
                      wrapperClassName="mdl-textfield__input"
                      editorClassName="editorClassName"
                      onEditorStateChange={onEditorStateChange}
                    />
                  </div> */}
                  <div className="mdl-textfield mdl-js-textfield txt-full-width">
                    <textarea
                      className="mdl-textfield__input"
                      rows="4"
                      onChange={(e) => setProductDiscription(e.target.value)}
                    ></textarea>
                  </div>
                </div>
                <div className="col-lg-6 p-t-20">
                  <label className="mdl-textfield__label">
                    Remaining Stock
                  </label>
                  <div className="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width">
                    <input
                      className="mdl-textfield__input"
                      type="text"
                      id="txtCity"
                      onChange={(e) => setStock(e.target.value)}
                    />
                  </div>
                </div>
                <div className="col-lg-12 p-t-20">
                  <Checkbox onChange={handleAr}>AR Enable</Checkbox>
                </div>
                {arChecked ? (
                  <div className="col-lg-12 p-t-20">
                    <label className="control-label col-md-3">
                      Upload AR Photo
                    </label>
                    {/* <div id="id_dropzone" className="dropzone" >
                  <input type="file" id="files" name="files" multiple onChange={handleUpload} />
                    <div className="dz-message">
                      <div className="dropIcon">
                        <i className="material-icons">cloud_upload</i>
                      </div>
                      <h3>Drop files here or click to upload.</h3>
                    </div>
                  </div> */}
                    <Dropzone
                      getUploadParams={getUploadParamsAR}
                      onChangeStatus={handleChangeStatusAR}
                      // onSubmit={handleUpload}
                      accept="image/*"
                      classNames="dropzone dz-message"
                      inputContent="Drop files here or click to upload."
                    />
                  </div>
                ) : (
                  <Fragment />
                )}
                <div className="col-lg-12 p-t-20">
                  <label className="control-label col-md-3">Upload Photo</label>
                  {/* <div id="id_dropzone" className="dropzone" >
                  <input type="file" id="files" name="files" multiple onChange={handleUpload} />
                    <div className="dz-message">
                      <div className="dropIcon">
                        <i className="material-icons">cloud_upload</i>
                      </div>
                      <h3>Drop files here or click to upload.</h3>
                    </div>
                  </div> */}
                  <Dropzone
                    getUploadParams={getUploadParams}
                    onChangeStatus={handleChangeStatus}
                    // onSubmit={handleUpload}
                    accept="image/*"
                    classNames="dropzone dz-message"
                    inputContent="Drop files here or click to upload."
                  />
                </div>
                <div className="col-lg-6 p-t-20">
                  <header>Variant Details</header>
                  <div>
                    <div className="form-group">
                      <label className="control-label">Size:</label>
                      <TagsInput value={sizeTags} onChange={handleSizeTag} />
                    </div>
                    <div className="form-group">
                      <label className="control-label">Color:</label>
                      <TagsInput value={colortags} onChange={handleColorTag} />
                    </div>
                    <div className="form-group">
                      <label className="control-label">Material</label>
                      <TagsInput
                        value={materialTags}
                        onChange={handleMaterial}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-12 p-t-20">
                  <label className="mdl-textfield__label">Shipping Fee</label>
                  <div className="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width">
                    <input
                      className="mdl-textfield__input"
                      type="number"
                      onChange={(e) => setShippingFee(e.target.value)}
                    />
                  </div>
                </div>
                <div className="col-lg-12 p-t-20">
                  <label className="mdl-textfield__label">
                    Product Pricing
                  </label>
                  <div className="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width">
                    <input
                      className="mdl-textfield__input"
                      type="number"
                      onChange={(e) => setPrice(e.target.value)}
                    />
                  </div>
                </div>
                <div className="col-lg-12 p-t-20 text-center">
                  <button
                    type="button"
                    className="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect m-b-10 m-r-20 btn-pink"
                    onClick={handleSubmit}
                  >
                    {/* <div
                        className={`mdl-spinner mdl-spinner--single-color mdl-js-spinner ${loading ? 'is-active' : ''}`}
                        style={{
                          width: "20px",
                          height: "20px",
                          marginRight: "5px",
                          marginTop: "5px",
                        }}></div> */}
                    Submit
                  </button>
                  <button
                    type="button"
                    className="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect m-b-10 btn-default"
                    onClick={handleCancel}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
