import React, { Component } from "react";
import { PageHeader } from "../components/common/PageHeader";
import  SideBar  from "../components/common/SideBar";
import { Index } from "../components/dashboard";

class Dashboard extends Component {
  render() {
    return (
      <div className="page-header-fixed sidemenu-closed-hidelogo page-content-white page-md header-white dark-sidebar-color logo-dark ">
        <div className="page-wrapper">
          <PageHeader />
          <div className="page-container">
            <SideBar activeMenu='0.0'/>
            <Index />
          </div>
        </div>
      </div>
    );
  }
}

export default Dashboard;
