import React from 'react';

export const Footer = props => {
    return (
        <div className="page-footer">
        <div className="page-footer-inner"> 2020 &copy; COZO MARKET PLACE
{/* <!--                    <a href="mailto:redstartheme@gmail.com" target="_top" className="makerCss">RedStar Theme</a>--> */}
        </div>
        <div className="scroll-to-top">
            <i className="icon-arrow-up"></i>
        </div>
    </div>
    )
}