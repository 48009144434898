import React, { useEffect, useState, Fragment } from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import { getAllOrders, filteredOrders } from "../../api/order/OrderProfile";
import { exportToExcel } from "../common/Services/Export";
import { DatePicker, Space } from "antd";
import { PaginationComponent } from "../common/Pagination";

const pageSize = 10;

export const AllOrdersComponent = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [selectedDate, setSelectedDate] = useState("");
  const [current, setCurrent] = useState(1);
  const [totalPage, setTotalPage] = useState(0);
  const [minIndex, setMinIndex] = useState(0);
  const [maxIndex, setMaxIndex] = useState(0);

  useEffect(() => {
    setIsLoading(true);
    getOrders();
  }, []);

  const getOrders = () => {
    getAllOrders().then((res) => {
      setIsLoading(false);
      setTableData(res.data.orders);
      setTotalPage(res.data && res.data.orders.length / pageSize);
      setMinIndex(0);
      setMaxIndex(pageSize);
    });
  };

  const filteredList = (obj) => {
    filteredOrders(obj).then((res) => {
      setIsLoading(false);
      setTableData(res.data.orders);
      setTotalPage(res.data && res.data.orders.length / pageSize);
      setMinIndex(0);
      setMaxIndex(pageSize);
    });
  };

  const handleDateChange = (date, dateString) => {
    setSelectedDate(dateString);
  };

  const handleFilter = () => {
    let obj = {};
    if (selectedDate) {
      obj.SD = selectedDate;
    }
    filteredList(obj);
  };

  const handleClear = () => {
    setSelectedDate("");
    getOrders();
  };

  const renderStatus = (status) => {
    switch (status) {
      case "Pending":
        return (
          <span className="label label-sm label-warning">Sent to Vendor</span>
        );
      case "Accepted":
        return (
          <span className="label label-sm label-danger">
            Accepted by Vendor
          </span>
        );
      case "Delivered":
        return <span className="label label-sm label-success">Delivered</span>;
      case "Delivering":
        return (
          <span className="label label-sm label-warning">Out for Delivery</span>
        );

      default:
        break;
    }
  };

  const handlePagination = (page) => {
    setCurrent(page);
    setMinIndex((page - 1) * pageSize);
    setMaxIndex(page * pageSize);
  };

  return (
    <Fragment>
      {isLoading && <div id="preloader"></div>}
      <div className="page-content-wrapper">
        <div className="page-content">
          <div className="page-bar">
            <div className="page-title-breadcrumb">
              <div className=" pull-left">
                <div className="page-title">All Orders</div>
              </div>
              <ol className="breadcrumb page-breadcrumb pull-right">
                <li>
                  <i className="fa fa-home"></i>&nbsp;
                  <a className="parent-item" href="index.html">
                    Home
                  </a>
                  &nbsp;<i className="fa fa-angle-right"></i>
                </li>
                <li>
                  <a className="parent-item" href="">
                    Orders
                  </a>
                  &nbsp;<i className="fa fa-angle-right"></i>
                </li>
                <li className="active">View Orders</li>
              </ol>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="card card-box">
                <div className="card-head">
                  <header>All Orders</header>
                  <div className="tools">
                    <a
                      className="fa fa-repeat btn-color box-refresh"
                      href=""
                    ></a>
                    <a
                      className="t-collapse btn-color fa fa-chevron-down"
                      href=""
                    ></a>
                    <a className="t-close btn-color fa fa-times" href=""></a>
                  </div>
                </div>
                <div className="card-body ">
                  <div className="row p-b-20">
                    <div className="col-md-6 col-sm-6 col-6">
                      <DatePicker
                        onChange={handleDateChange}
                        style={{ height: "33.8px" }}
                        allowClear
                        value={selectedDate ? moment(selectedDate) : null}
                      />
                      <button
                        type="button"
                        className="btn deepPink-bgcolor"
                        style={{
                          marginLeft: "5px",
                          marginRight: "5px",
                          marginBottom: "5px",
                        }}
                        onClick={handleFilter}
                      >
                        Search
                      </button>
                      <button
                        type="button"
                        className="btn btn-default"
                        style={{ marginBottom: "5px" }}
                        onClick={handleClear}
                      >
                        Clear
                      </button>
                    </div>
                    <div className="col-md-6 col-sm-6 col-6">
                      <div className="btn-group pull-right">
                        <a
                          className="btn deepPink-bgcolor  btn-outline dropdown-toggle"
                          data-toggle="dropdown"
                        >
                          Tools
                          <i className="fa fa-angle-down"></i>
                        </a>
                        <ul className="dropdown-menu pull-right">
                          <li>
                            <button style={{ marginLeft: "16px" }}>
                              <i className="fa fa-print"></i> Print{" "}
                            </button>
                          </li>
                          <li>
                            <button style={{ marginLeft: "16px" }}>
                              <i className="fa fa-file-pdf-o"></i> Save as PDF{" "}
                            </button>
                          </li>
                          <li>
                            <button
                              style={{ marginLeft: "16px" }}
                              onClick={() =>
                                exportToExcel("example4", "All Orders")
                              }
                            >
                              <i className="fa fa-file-excel-o"></i> Export to
                              Excel{" "}
                            </button>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="table-scrollable">
                    <table
                      className="table table-hover table-checkable order-column full-width"
                      id="example4"
                    >
                      <thead>
                        <tr>
                          <th>No</th>
                          <th>Name</th>
                          <th>Phone</th>
                          <th>Amount (KWD)</th>
                          <th>Created Date</th>
                          <th>Status</th>
                          {/* <th>Room Type</th> */}
                          <th>Update Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        {tableData?.map((item, key) => {
                          return (
                            <Fragment>
                              {key >= minIndex && key < maxIndex && (
                                <tr>
                                  <td>{key + 1}</td>
                                  <td>{item?.customer?.fullName}</td>
                                  <td>{item?.customer?.phone_number}</td>
                                  <td>{item?.amount.toFixed(2)}</td>
                                  <td>
                                    {moment(item?.date).format("YYYY-MM-DD")}
                                  </td>
                                  <td>{renderStatus(item?.status)}</td>
                                  <td>
                                    {item.status != "Delivered" ? (
                                      <Link
                                        to={{
                                          pathname: "/order-detail",
                                          state: {
                                            id: item && item._id,
                                            currentStatus: item && item?.status,
                                          },
                                        }}
                                        className="btn btn-tbl-edit btn-xs"
                                      >
                                        <i className="fa fa-pencil"></i>
                                      </Link>
                                    ) : (
                                      "Done"
                                    )}
                                  </td>
                                </tr>
                              )}
                            </Fragment>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <PaginationComponent
                      pageSize={pageSize}
                      current={current}
                      total={tableData && tableData.length}
                      onChange={handlePagination}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
