import React from "react";
import moment from "moment";
import { Fragment } from "react";

export const OrderedProducts = (props) => {
  const { tableData, minIndex, maxIndex } = props;

  return (
    <table
      className="table table-hover table-checkable order-column full-width"
      id="example4"
    >
      <thead>
        <tr>
          <th className="center"> Id</th>
          <th className="center"> </th>
          <th className="center"> Name </th>
          <th className="center"> Description </th>
          <th className="center"> Amount </th>
          <th className="center"> Date </th>
        </tr>
      </thead>
      <tbody>
        {tableData?.map((item, key) => {
          return (
            <Fragment>
              {key >= minIndex && key < maxIndex && (
                <tr className="odd gradeX">
                  <td className="user-circle-img">
                    <span>{key + 1}</span>
                  </td>
                  <td className="user-circle-img">
                    <span>
                      <img
                        src={item?.product?.product?.images[0]}
                        style={{ width: "50px", height: "50px" }}
                      />
                    </span>
                  </td>
                  <td className="center">
                    <span>{item?.product?.product?.name}</span>
                  </td>
                  <td className="center">
                    <span>{item?.product?.product?.description}</span>
                  </td>
                  <td className="center">
                    <span>{item.amount}</span>
                  </td>
                  <td className="center">
                    <span>
                      {moment(item?.product?.product?.created_at).format(
                        "YYYY-MM-DD"
                      )}
                    </span>
                  </td>
                </tr>
              )}
            </Fragment>
          );
        })}
      </tbody>
    </table>
  );
};
