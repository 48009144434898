import React from "react";
import { Pagination } from "antd";

export const PaginationComponent = (props) => {
  const { pageSize, current, total, onChange } = props;
  return (
    <Pagination
      pageSize={pageSize}
      current={current}
      total={total}
      onChange={onChange}
    />
  );
};
