import React, { useState, useEffect } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { Alert } from "antd";
import { logIn } from "../../api/user/auth";
import {
  AUTHENTICATED,
  ACCESS_TOKEN,
  GET_USER,
} from "../../redux/actions/ActionTypes";
import { authenticate } from "../../redux/actions/Auth";
import { getUser } from "../../api/user/UserProfile";
import swal from "sweetalert";

export const LoginComponent = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(false);
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [isRemembered, setRemember] = useState(false);
  const emailRegex = RegExp(
    '^(([^<>()\\[\\]\\\\.,;:\\s@"]+(\\.[^<>()\\[\\]\\\\.,;:\\s@"]+)*)|(".+"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$'
  );

  useEffect(() => {
    if (localStorage.email && localStorage.password && localStorage.remeber) {
      setEmail(localStorage.email);
      setPassword(localStorage.password);
      setRemember(true);
    }
  }, []);

  const handleLogin = () => {
    if (!email || !password) {
      setError(true);
      swal("Please Fill Required Fields !", "", "error");
    } else {
      const obj = {
        email,
        password,
      };
      const errors = validateForm(obj);
      // if (!Object.keys(errors).length) {
      logIn(obj)
        .then((res) => {
          if (res.data.status == "success") {
            setLoading(false);
            setError(false);
            dispatch(authenticate(true));
            localStorage.setItem(ACCESS_TOKEN, `Bearer ${res.data.token}`);
            if (isRemembered) {
              localStorage.email = email;
              localStorage.password = password;
              localStorage.remeber = true;
            } else {
              localStorage.removeItem("email");
              localStorage.removeItem("password");
              localStorage.removeItem("remeber");
            }
            swal("Successfully Logged!", "", "success");
            history.push({
              pathname: "/dashboard",
            });
            getUser().then((res) => {
              dispatch({ type: GET_USER, payload: res.data });
            });
          } else {
            setError(true);
            setMessage("Something went wrong !!!");
            setLoading(false);
            dispatch(authenticate(false));
            swal("Something went wrong !", "", "error");
          }
        })
        .catch((error) => {
          swal(
            `${error.response.data.message}`,
            "You clicked the button!",
            "error"
          );
          setLoading(false);
          // if (e.response && e.response.data && e.response.data.message) {
          //   setError(e.response.data.message);
          // }
        });
      // } else {
      // }
    }
  };

  const validateForm = (data) => {
    let errors = {};
    if (!data.email) {
      errors.email = "Email Required !";
    } else if (!emailRegex.test(data.email)) {
      errors.email = "Invalid Email !";
    } else if (!data.password) {
      errors.password = "Password Required !";
    }

    return errors;
  };

  return (
    <div className="wrap-login100">
      <div className="login100-form validate-form">
        <img
          alt=""
          className="login100-form-logo"
          src="assets/img/Vendor_Vertical.png"
        />
        <span className="login100-form-title p-b-34 p-t-27">Log in</span>
        <div
          className="wrap-input100 validate-input"
          data-validate="Enter username"
        >
          <input
            className="input100"
            type="text"
            name="username"
            placeholder="Username"
            onChange={(e) => setEmail(e.target.value)}
          />
          <span className="focus-input100" data-placeholder="&#xf207;"></span>
        </div>
        <div
          className="wrap-input100 validate-input"
          data-validate="Enter password"
        >
          <input
            className="input100"
            type="password"
            name="pass"
            placeholder="Password"
            onChange={(e) => setPassword(e.target.value)}
          />
          <span className="focus-input100" data-placeholder="&#xf191;"></span>
        </div>
        <div className="contact100-form-checkbox">
          <input
            className="input-checkbox100"
            id="ckb1"
            type="checkbox"
            name="remember-me"
            checked={isRemembered}
            onChange={(e) => setRemember(!isRemembered)}
          />
          <label className="label-checkbox100" for="ckb1">
            Remember me
          </label>
        </div>
        <div className="container-login100-form-btn">
          <button className="login100-form-btn" onClick={handleLogin}>
            Login
          </button>
        </div>
        <div className="text-center p-t-90">
          <a className="txt1" href="/forget-password">
            Forgot Password?
          </a>
        </div>
      </div>
    </div>
  );
};

export default connect()(LoginComponent);
