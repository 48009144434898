import React, { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  getAllProducts,
  deleteProduct,
  addToSellProduct,
} from "../../api/product/ProductProfile";
import swal from "sweetalert";
import { Checkbox } from "antd";
import { PaginationComponent } from "../common/Pagination";

const pageSize = 10;

export const AllProducts = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [modelVisible, setModelVisible] = useState(false);
  const [checked, setChecked] = useState(false);
  const [current, setCurrent] = useState(1);
  const [totalPage, setTotalPage] = useState(0);
  const [minIndex, setMinIndex] = useState(0);
  const [maxIndex, setMaxIndex] = useState(0);

  useEffect(() => {
    setIsLoading(true);
    getAllProducts().then((res) => {
      setTableData(res.data.products);
      setIsLoading(false);
      setTotalPage(res.data && res.data.products.length / pageSize);
      setMinIndex(0);
      setMaxIndex(pageSize);
    });
  }, []);

  const handleDelete = (id) => {
    deleteProduct(id)
      .then((res) => {
        if (res.data.status == "success") {
          setModelVisible(true);
          swal(
            "Successfully Deleted the Product!",
            "You clicked the button!",
            "success"
          );
          getAllProducts().then((res) => {
            setTableData(res.data.products);
            setTotalPage(res.data && res.data.products.length / pageSize);
            setMinIndex(0);
            setMaxIndex(pageSize);
          });
          setTimeout(() => {
            setModelVisible(false);
          }, 3000);
        }
      })
      .catch((error) => {
        swal(`${error.response.data.message}`, "Please Try Again!", "error");
        // if (e.response && e.response.data && e.response.data.message) {
        //   setError(e.response.data.message);
        // }
      });
  };

  const handleChecked = (e, id, addToSell) => {
    let obj = {};
    if (addToSell && addToSell == true) {
      obj.add_to_sell = false;
    } else {
      obj.add_to_sell = true;
    }
    addToSellProduct(id, obj).then((res) => {
      if (res.data.status == "success") {
        setModelVisible(true);
        swal(
          "Successfully Added to Sell!",
          "You clicked the button!",
          "success"
        );
        getAllProducts().then((res) => {
          setTableData(res.data.products);
          setTotalPage(res.data && res.data.products.length / pageSize);
          setMinIndex(0);
          setMaxIndex(pageSize);
        });
        setTimeout(() => {
          setModelVisible(false);
        }, 3000);
      }
    });
    // .catch((error) => {
    //   swal(`${error.response.data.message}`, "Please Try Again!", "error");
    //   // if (e.response && e.response.data && e.response.data.message) {
    //   //   setError(e.response.data.message);
    //   // }
    // });
  };

  const handlePagination = (page) => {
    setCurrent(page);
    setMinIndex((page - 1) * pageSize);
    setMaxIndex(page * pageSize);
  };

  return (
    <Fragment>
      {isLoading && <div id="preloader"></div>}
      <div className="page-content-wrapper">
        <div className="page-content">
          <div className="page-bar">
            <div className="page-title-breadcrumb">
              <div className=" pull-left">
                <div className="page-title">All Products</div>
              </div>
              <ol className="breadcrumb page-breadcrumb pull-right">
                <li>
                  <i className="fa fa-home"></i>&nbsp;
                  <a className="parent-item" href="index.html">
                    Home
                  </a>
                  &nbsp;<i className="fa fa-angle-right"></i>
                </li>
                <li>
                  <a className="parent-item" href="">
                    Products
                  </a>
                  &nbsp;<i className="fa fa-angle-right"></i>
                </li>
                <li className="active">All Products</li>
              </ol>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="card card-box">
                <div className="card-head">
                  <header>All Products</header>
                  <div className="tools">
                    <a
                      className="fa fa-repeat btn-color box-refresh"
                      href="javascript:;"
                    ></a>
                    <a
                      className="t-collapse btn-color fa fa-chevron-down"
                      href="javascript:;"
                    ></a>
                    <a
                      className="t-close btn-color fa fa-times"
                      href="javascript:;"
                    ></a>
                  </div>
                </div>
                <div className="card-body ">
                  <div className="row p-b-20">
                    <div className="col-md-6 col-sm-6 col-6">
                      <div className="btn-group">
                        <Link
                          to="/add-product"
                          id="addRow"
                          className="btn btn-info"
                        >
                          Add Product <i className="fa fa-plus"></i>
                        </Link>
                      </div>
                    </div>
                    <div className="col-md-6 col-sm-6 col-6">
                      <div className="btn-group pull-right">
                        <a
                          className="btn deepPink-bgcolor  btn-outline dropdown-toggle"
                          data-toggle="dropdown"
                        >
                          Tools
                          <i className="fa fa-angle-down"></i>
                        </a>
                        <ul className="dropdown-menu pull-right">
                          <li>
                            <a href="javascript:;">
                              <i className="fa fa-print"></i> Print{" "}
                            </a>
                          </li>
                          <li>
                            <a href="javascript:;">
                              <i className="fa fa-file-pdf-o"></i> Save as PDF{" "}
                            </a>
                          </li>
                          <li>
                            <a href="javascript:;">
                              <i className="fa fa-file-excel-o"></i> Export to
                              Excel{" "}
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="table-scrollable">
                    <table
                      className="table table-hover table-checkable order-column full-width"
                      id="example4"
                    >
                      <thead>
                        <tr>
                          <th className="center"> Product Id</th>
                          <th className="center"> Name </th>
                          <th className="center"> Image </th>
                          <th className="center"> Price(KWD) </th>
                          <th className="center"> Quantity </th>
                          <th className="center"> Type </th>
                          <th className="center"> Stocks Remaining </th>
                          <th className="center"> Add to Sell </th>
                          <th className="center"> Status </th>
                          <th className="center"> Action </th>
                        </tr>
                      </thead>
                      <tbody>
                        {tableData &&
                          tableData.map((item, key) => {
                            return (
                              <Fragment>
                                {key >= minIndex && key < maxIndex && (
                                  <tr className="odd gradeX">
                                    <td className="user-circle-img">
                                      <span>{key + 1}</span>
                                    </td>
                                    <td className="center">{item.name}</td>
                                    <td className="user-circle-img">
                                      <img
                                        src="assets/img/user/user1.jpg"
                                        alt=""
                                      />
                                    </td>
                                    <td className="center">
                                      <span>{item.amount.toFixed(2)}</span>
                                    </td>
                                    <td className="center">
                                      <span>{item.sale}</span>
                                    </td>
                                    <td className="center">
                                      <span>{item.sub_category}</span>
                                    </td>
                                    <td className="center">
                                      <span>{item.stock}</span>
                                    </td>
                                    <td className="center">
                                      <span>
                                        <Checkbox
                                          defaultChecked={
                                            item.add_to_sell
                                              ? item.add_to_sell
                                              : ""
                                          }
                                          // checked={
                                          //   item.add_to_sell ? item.add_to_sell : ""
                                          // }
                                          onChange={(e) =>
                                            handleChecked(
                                              e,
                                              item._id,
                                              item.add_to_sell
                                                ? item.add_to_sell
                                                : ""
                                            )
                                          }
                                        />
                                      </span>
                                    </td>
                                    <td className="center">
                                      <span className="label label-sm label-success">
                                        {item.status}
                                      </span>
                                    </td>
                                    <td className="center">
                                      <Link
                                        to={{
                                          pathname: "/update-product",
                                          state: item,
                                        }}
                                        className="btn btn-tbl-edit btn-xs"
                                      >
                                        <i className="fa fa-pencil"></i>
                                      </Link>
                                      <button
                                        className="btn btn-tbl-delete btn-xs"
                                        onClick={() => handleDelete(item._id)}
                                      >
                                        <i className="fa fa-trash-o "></i>
                                      </button>
                                    </td>
                                  </tr>
                                )}
                              </Fragment>
                            );
                          })}
                      </tbody>
                    </table>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <PaginationComponent
                      pageSize={pageSize}
                      current={current}
                      total={tableData && tableData.length}
                      onChange={handlePagination}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
