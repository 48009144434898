import axios from 'axios';

export const getUser = () => {
    return axios.get(`${process.env.REACT_APP_API_URL}/vendor`, {
        headers: {
            Authorization: localStorage.access_token
        }
    })
}

export const updateLocation = data => {
    return axios.patch(`${process.env.REACT_APP_API_URL}/vendor/location`, data, {
        headers: {
            Authorization: localStorage.access_token
        }
    })
}