import React, { Component } from "react";
import { PageHeader } from "../components/common/PageHeader";
import { Footer } from "../components/common/Footer";
import SideBar from "../components/common/SideBar";
import { ViewOrder } from '../components/Orders/ViewOrder';


class OrderDetailManagement extends Component {
    constructor(props) {
        super(props);
        this.state = {  }
    }
    render() { 
        return ( 
            <div className="page-header-fixed sidemenu-closed-hidelogo page-content-white page-md header-white dark-sidebar-color logo-dark">
            <div className="page-wrapper">
            <PageHeader />
            <div className="page-container">
                <SideBar></SideBar>
                <ViewOrder />
            </div>
            <Footer></Footer>
            </div>
        </div>
         );
    }
}
 
export default OrderDetailManagement;