import React from 'react';

export const ChatSideBar = props => {
    return (
        <div className="chat-sidebar-container" data-close-on-body-click="false">
        <div className="chat-sidebar">
            <ul className="nav nav-tabs">
                <li className="nav-item">
                    <a href="#quick_sidebar_tab_2" className="nav-link tab-icon active"  data-toggle="tab"> Chat
                    </a>
                </li>
                <li className="nav-item">
                    <a href="#quick_sidebar_tab_3" className="nav-link tab-icon"  data-toggle="tab">  Settings
                    </a>
                </li>
            </ul>
            <div className="tab-content">
                {/* <!-- Start Doctor Chat --> */}
                <div className="tab-pane chat-sidebar-chat in show active animated slideInRight" id="quick_sidebar_tab_2">
                    <div className="chat-sidebar-list">
                        <div className="chat-sidebar-chat-users slimscroll-style" data-rail-color="#ddd" data-wrapper-className="chat-sidebar-list">
                            <div className="chat-header"><h5 className="list-heading">Online</h5></div>
                            <ul className="media-list list-items">
                                <li className="media"><img className="media-object" src="assets/img/user/user3.jpg" width="35" height="35" alt="..." />
                                    <i className="online dot"></i>
                                    <div className="media-body">
                                        <h5 className="media-heading">John Deo</h5>
                                        <div className="media-heading-sub">Spine Surgeon</div>
                                    </div>
                                </li>
                                <li className="media">
                                    <div className="media-status">
                                        <span className="badge badge-success">5</span>
                                    </div> <img className="media-object" src="assets/img/user/user1.jpg" width="35" height="35" alt="..." />
                                    <i className="busy dot"></i>
                                    <div className="media-body">
                                        <h5 className="media-heading">Rajesh</h5>
                                        <div className="media-heading-sub">Director</div>
                                    </div>
                                </li>
                                <li className="media"><img className="media-object" src="assets/img/user/user5.jpg" width="35" height="35" alt="..." />
                                    <i className="away dot"></i>
                                    <div className="media-body">
                                        <h5 className="media-heading">Jacob Ryan</h5>
                                        <div className="media-heading-sub">Ortho Surgeon</div>
                                    </div>
                                </li>
                                <li className="media">
                                    <div className="media-status">
                                        <span className="badge badge-danger">8</span>
                                    </div> <img className="media-object" src="assets/img/user/user4.jpg" width="35" height="35" alt="..." />
                                    <i className="online dot"></i>
                                    <div className="media-body">
                                        <h5 className="media-heading">Kehn Anderson</h5>
                                        <div className="media-heading-sub">CEO</div>
                                    </div>
                                </li>
                                <li className="media"><img className="media-object" src="assets/img/user/user2.jpg" width="35" height="35" alt="..." />
                                    <i className="busy dot"></i>
                                    <div className="media-body">
                                        <h5 className="media-heading">Sarah Smith</h5>
                                        <div className="media-heading-sub">Anaesthetics</div>
                                    </div>
                                </li>
                                <li className="media"><img className="media-object" src="assets/img/user/user7.jpg" width="35" height="35" alt="..." />
                                    <i className="online dot"></i>
                                    <div className="media-body">
                                        <h5 className="media-heading">Vlad Cardella</h5>
                                        <div className="media-heading-sub">Cardiologist</div>
                                    </div>
                                </li>
                            </ul>
                            <div className="chat-header"><h5 className="list-heading">Offline</h5></div>
                            <ul className="media-list list-items">
                                <li className="media">
                                    <div className="media-status">
                                        <span className="badge badge-warning">4</span>
                                    </div> <img className="media-object" src="assets/img/user/user6.jpg" width="35" height="35" alt="..." />
                                    <i className="offline dot"></i>
                                    <div className="media-body">
                                        <h5 className="media-heading">Jennifer Maklen</h5>
                                        <div className="media-heading-sub">Nurse</div>
                                        <div className="media-heading-small">Last seen 01:20 AM</div>
                                    </div>
                                </li>
                                <li className="media"><img className="media-object" src="assets/img/user/user8.jpg" width="35" height="35" alt="..." />
                                    <i className="offline dot"></i>
                                    <div className="media-body">
                                        <h5 className="media-heading">Lina Smith</h5>
                                        <div className="media-heading-sub">Ortho Surgeon</div>
                                        <div className="media-heading-small">Last seen 11:14 PM</div>
                                    </div>
                                </li>
                                <li className="media">
                                    <div className="media-status">
                                        <span className="badge badge-success">9</span>
                                    </div> <img className="media-object" src="assets/img/user/user9.jpg" width="35" height="35" alt="..." />
                                    <i className="offline dot"></i>
                                    <div className="media-body">
                                        <h5 className="media-heading">Jeff Adam</h5>
                                        <div className="media-heading-sub">Compounder</div>
                                        <div className="media-heading-small">Last seen 3:31 PM</div>
                                    </div>
                                </li>
                                <li className="media"><img className="media-object" src="assets/img/user/user10.jpg" width="35" height="35" alt="..." />
                                    <i className="offline dot"></i>
                                    <div className="media-body">
                                        <h5 className="media-heading">Anjelina Cardella</h5>
                                        <div className="media-heading-sub">Physiotherapist</div>
                                        <div className="media-heading-small">Last seen 7:45 PM</div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="chat-sidebar-item">
                        <div className="chat-sidebar-chat-user">
                            <div className="page-quick-sidemenu">
                                <a href="javascript:;" className="chat-sidebar-back-to-list">
                                    <i className="fa fa-angle-double-left"></i>Back
                                </a>
                            </div>
                            <div className="chat-sidebar-chat-user-messages">
                                <div className="post out">
                                    <img className="avatar" alt="" src="assets/img/dp.jpg" />
                                    <div className="message">
                                        <span className="arrow"></span> <a href="javascript:;" className="name">Kiran Patel</a> <span className="datetime">9:10</span>
                                        <span className="body-out"> could you send me menu icons ? </span>
                                    </div>
                                </div>
                                <div className="post in">
                                    <img className="avatar" alt="" src="assets/img/user/user5.jpg" />
                                    <div className="message">
                                        <span className="arrow"></span> <a href="javascript:;" className="name">Jacob Ryan</a> <span className="datetime">9:10</span>
                                        <span className="body"> please give me 10 minutes. </span>
                                    </div>
                                </div>
                                <div className="post out">
                                    <img className="avatar" alt="" src="assets/img/dp.jpg" />
                                    <div className="message">
                                        <span className="arrow"></span> <a href="javascript:;" className="name">Kiran Patel</a> <span className="datetime">9:11</span>
                                        <span className="body-out"> ok fine :) </span>
                                    </div>
                                </div>
                                <div className="post in">
                                    <img className="avatar" alt="" src="assets/img/user/user5.jpg" />
                                    <div className="message">
                                        <span className="arrow"></span> <a href="javascript:;" className="name">Jacob Ryan</a> <span className="datetime">9:22</span>
                                        <span className="body">Sorry for
                                            the delay. i sent mail to you. let me know if it is ok or not.</span>
                                    </div>
                                </div>
                                <div className="post out">
                                    <img className="avatar" alt="" src="assets/img/dp.jpg" />
                                    <div className="message">
                                        <span className="arrow"></span> <a href="javascript:;" className="name">Kiran Patel</a> <span className="datetime">9:26</span>
                                        <span className="body-out"> it is perfect! :) </span>
                                    </div>
                                </div>
                                <div className="post out">
                                    <img className="avatar" alt="" src="assets/img/dp.jpg" />
                                    <div className="message">
                                        <span className="arrow"></span> <a href="javascript:;" className="name">Kiran Patel</a> <span className="datetime">9:26</span>
                                        <span className="body-out"> Great! Thanks. </span>
                                    </div>
                                </div>
                                <div className="post in">
                                    <img className="avatar" alt="" src="assets/img/user/user5.jpg" />
                                    <div className="message">
                                        <span className="arrow"></span> <a href="javascript:;" className="name">Jacob Ryan</a> <span className="datetime">9:27</span>
                                        <span className="body"> it is my pleasure :) </span>
                                    </div>
                                </div>
                            </div>
                            <div className="chat-sidebar-chat-user-form">
                                <div className="input-group">
                                    <input type="text" className="form-control" placeholder="Type a message here..." />
                                    <div className="input-group-btn">
                                        <button type="button" className="btn deepPink-bgcolor">
                                            <i className="fa fa-arrow-right"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <!-- End Doctor Chat --> */}
                {/* <!-- Start Setting Panel --> */}
                <div class="tab-pane chat-sidebar-settings animated slideInUp" id="quick_sidebar_tab_3">
                    <div class="chat-sidebar-settings-list slimscroll-style">
                        <div class="chat-header"><h5 class="list-heading">Layout Settings</h5></div>
                        <div class="chatpane inner-content ">
                            <div class="settings-list">
                                <div class="setting-item">
                                    <div class="setting-text">Sidebar Position</div>
                                    <div class="setting-set">
                                        <select class="sidebar-pos-option form-control input-inline input-sm input-small ">
                                            <option value="left" selected="selected">Left</option>
                                            <option value="right">Right</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="setting-item">
                                    <div class="setting-text">Header</div>
                                    <div class="setting-set">
                                        <select class="page-header-option form-control input-inline input-sm input-small ">
                                            <option value="fixed" selected="selected">Fixed</option>
                                            <option value="default">Default</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="setting-item">
                                    <div class="setting-text">Sidebar Menu </div>
                                    <div class="setting-set">
                                        <select class="sidebar-menu-option form-control input-inline input-sm input-small ">
                                            <option value="accordion" selected="selected">Accordion</option>
                                            <option value="hover">Hover</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="setting-item">
                                    <div class="setting-text">Footer</div>
                                    <div class="setting-set">
                                        <select class="page-footer-option form-control input-inline input-sm input-small ">
                                            <option value="fixed">Fixed</option>
                                            <option value="default" selected="selected">Default</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div class="chat-header"><h5 class="list-heading">Account Settings</h5></div>
                            <div class="settings-list">
                                <div class="setting-item">
                                    <div class="setting-text">Notifications</div>
                                    <div class="setting-set">
                                        <div class="switch">
                                            <label class = "mdl-switch mdl-js-switch mdl-js-ripple-effect"
                                                   for = "switch-1">
                                                <input type = "checkbox" id = "switch-1"
                                                       class = "mdl-switch__input" checked />
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div class="setting-item">
                                    <div class="setting-text">Show Online</div>
                                    <div class="setting-set">
                                        <div class="switch">
                                            <label class = "mdl-switch mdl-js-switch mdl-js-ripple-effect"
                                                   for = "switch-7">
                                                <input type = "checkbox" id = "switch-7"
                                                       class = "mdl-switch__input" checked />
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div class="setting-item">
                                    <div class="setting-text">Status</div>
                                    <div class="setting-set">
                                        <div class="switch">
                                            <label class = "mdl-switch mdl-js-switch mdl-js-ripple-effect"
                                                   for = "switch-2">
                                                <input type = "checkbox" id = "switch-2"
                                                       class = "mdl-switch__input" checked />
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div class="setting-item">
                                    <div class="setting-text">2 Steps Verification</div>
                                    <div class="setting-set">
                                        <div class="switch">
                                            <label class = "mdl-switch mdl-js-switch mdl-js-ripple-effect"
                                                   for = "switch-3">
                                                <input type = "checkbox" id = "switch-3"
                                                       class = "mdl-switch__input" checked />
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="chat-header"><h5 class="list-heading">General Settings</h5></div>
                            <div class="settings-list">
                                <div class="setting-item">
                                    <div class="setting-text">Location</div>
                                    <div class="setting-set">
                                        <div class="switch">
                                            <label class = "mdl-switch mdl-js-switch mdl-js-ripple-effect"
                                                   for = "switch-4">
                                                <input type = "checkbox" id = "switch-4"
                                                       class = "mdl-switch__input" checked />
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div class="setting-item">
                                    <div class="setting-text">Save Histry</div>
                                    <div class="setting-set">
                                        <div class="switch">
                                            <label class = "mdl-switch mdl-js-switch mdl-js-ripple-effect"
                                                   for = "switch-5">
                                                <input type = "checkbox" id = "switch-5"
                                                       class = "mdl-switch__input" checked />
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div class="setting-item">
                                    <div class="setting-text">Auto Updates</div>
                                    <div class="setting-set">
                                        <div class="switch">
                                            <label class = "mdl-switch mdl-js-switch mdl-js-ripple-effect"
                                                   for = "switch-6">
                                                <input type = "checkbox" id = "switch-6"
                                                       class = "mdl-switch__input" checked />
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    )
}