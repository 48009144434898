import React, { Component } from "react";
import { PageHeader } from "../components/common/PageHeader";
import { Footer } from "../components/common/Footer";
import SideBar from "../components/common/SideBar";
import { CustomerList } from "../components/customers/CustomerList";

class Customers extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <div className="page-header-fixed sidemenu-closed-hidelogo page-content-white page-md header-white dark-sidebar-color logo-dark">
        <div className="page-wrapper">
          <PageHeader />
          <div className="page-container">
            <SideBar activeMenu="1.0" />
            <CustomerList />
          </div>
          <Footer />
        </div>
      </div>
    );
  }
}

export default Customers;
