import React, { Component } from "react";
import { connect } from "react-redux";
import { ChatSideBar } from "../components/common/ChatSideBar";
import { PageHeader } from "../components/common/PageHeader";
import SideBar from "../components/common/SideBar";
import { Footer } from "../components/common/Footer";
import { getUser } from "../api/user/UserProfile";
import { TransactionDetails } from "../components/Transaction/TransactionDetails";
import { ChangePassword } from "../components/auth/ChangePasswordComponent";

class UserProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userDetails: {},
    };
  }

  componentDidMount() {
    getUser().then((res) => {
      this.setState({ userDetails: res.data.vendor });
    });
  }

  render() {
    const { userDetails } = this.state;
    return (
      <div className="page-header-fixed sidemenu-closed-hidelogo page-content-white page-md header-white dark-sidebar-color logo-dark">
        <div className="page-wrapper">
          <PageHeader />
          <div className="page-container">
            <SideBar activeMenu='2.0' />
            <div className="page-content-wrapper">
              <div className="page-content">
                <div className="page-bar">
                  <div className="page-title-breadcrumb">
                    <div className=" pull-left">
                      <div className="page-title">User Profile</div>
                    </div>
                    <ol className="breadcrumb page-breadcrumb pull-right">
                      <li>
                        <i className="fa fa-home"></i>&nbsp;
                        <a className="parent-item" href="index.html">
                          Home
                        </a>
                        &nbsp;<i className="fa fa-angle-right"></i>
                      </li>
                      <li>
                        <a className="parent-item" href="">
                          Extra
                        </a>
                        &nbsp;<i className="fa fa-angle-right"></i>
                      </li>
                      <li className="active">User Profile</li>
                    </ol>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-12">
                    {/* <!-- BEGIN PROFILE SIDEBAR --> */}
                    <div className="profile-sidebar">
                      <div className="card card-topline-aqua">
                        <div className="card-body no-padding height-9">
                          <div className="row">
                            <div className="profile-userpic">
                              <img
                                src="assets/img/dp.jpg"
                                className="img-responsive"
                                alt=""
                              />{" "}
                            </div>
                          </div>
                          <div className="profile-usertitle">
                            <div className="profile-usertitle-name">
                              {" "}
                              {userDetails.name_of_business}{" "}
                            </div>
                            {/* <div className="profile-usertitle-job">
                              {" "}
                              Designer{" "}
                            </div> */}
                          </div>
                          {/* <ul className="list-group list-group-unbordered">
                            <li className="list-group-item">
                              <b>Shop URL</b>{" "}
                              <a
                                href="https://www.youtube.com/"
                                className="pull-right">
                                https://www.youtube.com/
                              </a>
                            </li>
                            <li className="list-group-item">
                              <b>Created At</b>{" "}
                              <a className="pull-right">Home Designers.lk</a>
                            </li>
                          </ul> */}
                          {/* <!-- END SIDEBAR USER TITLE --> */}
                          {/* <!-- SIDEBAR BUTTONS --> */}
                          {/* <div className="profile-userbuttons">
                            <a href="edit_profile.html">
                              <button
                                type="button"
                                className="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect m-b-10 btn-circle btn-pink">
                                Edit Profile
                              </button>
                            </a>
                          </div> */}
                          {/* <!-- END SIDEBAR BUTTONS --> */}
                        </div>
                      </div>
                      <div className="card">
                        <div className="card-head card-topline-aqua">
                          <header>About Me</header>
                        </div>
                        <div className="card-body no-padding height-9">
                          <div className="profile-desc">
                            {userDetails.about}
                          </div>
                          {/* <ul className="list-group list-group-unbordered">
                            <li className="list-group-item">
                              <b>Gender </b>
                              <div className="profile-desc-item pull-right">
                                Female
                              </div>
                            </li>
                            <li className="list-group-item">
                              <b>Vendor Group</b>
                              <div className="profile-desc-item pull-right">
                                Designers
                              </div>
                            </li>
                            <li className="list-group-item">
                              <b>Company</b>
                              <div className="profile-desc-item pull-right">
                                Home Designers.lk
                              </div>
                            </li>
                            <li className="list-group-item">
                              <b>Designation</b>
                              <div className="profile-desc-item pull-right">
                                Designer
                              </div>
                            </li>
                          </ul> */}
                        </div>
                      </div>
                      <div className="card">
                        <div className="card-head card-topline-aqua">
                          <header>Address</header>
                        </div>
                        <div className="card-body no-padding height-9">
                          <div className="row text-center m-t-10">
                            <div className="col-md-12">
                              <p>
                                {userDetails.location}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="card">
                        <div className="card-head card-topline-aqua">
                          <header>Email</header>
                        </div>
                        <div className="card-body no-padding height-9">
                          <div className="row text-center m-t-10">
                            <div className="col-md-12">
                              <p>{userDetails.email}</p>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="card">
                        <div className="card-head card-topline-aqua">
                          <header>Contact Number</header>
                        </div>
                        <div className="card-body no-padding height-9">
                          <div className="row text-center m-t-10">
                            <div className="col-md-12">
                              <p>{userDetails.phone_number}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <!-- END BEGIN PROFILE SIDEBAR --> */}
                    {/* <!-- BEGIN PROFILE CONTENT --> */}
                    <div className="profile-content">
                      <div className="row">
                        <div className="profile-tab-box">
                          <div className="p-l-20">
                            <ul className="nav ">
                              <li className="nav-item tab-all">
                                <a
                                  className="nav-link active show"
                                  href="#tab1"
                                  data-toggle="tab">
                                  About Me
                                </a>
                              </li>
                              <li className="nav-item tab-all p-l-20">
                                <a
                                  className="nav-link"
                                  href="#tab3"
                                  data-toggle="tab">
                                  Account Settings
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div className="white-box">
                          {/* <!-- Tab panes --> */}
                          <div className="tab-content">
                            <div className="tab-pane" id="tab3">
                              <div className="row">
                                <div className="col-md-12 col-sm-12">
                                  <ChangePassword />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <TransactionDetails />
                      </div>
                    </div>
                    {/* <!-- END PROFILE CONTENT --> */}
                  </div>
                </div>
                <ChatSideBar />
              </div>
            </div>

            <Footer />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user.user,
});

export default connect(mapStateToProps)(UserProfile);
