import React, { Fragment, useState} from "react";


export const ChangePassword = (props) => {
    const [username, setUserName] = useState("");
    const [oldPassword, setOldPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");

    const handleChangePassword = () => {
        let obj = {
            username,
            oldPassword,
            newPassword
        };
    }

  return (
    <Fragment>
      <div className="card-head">
        <header>Password Change</header>
        <button
          id="panel-button2"
          className="mdl-button mdl-js-button mdl-button--icon pull-right"
          data-upgraded=",MaterialButton">
          <i className="material-icons">more_vert</i>
        </button>
        <ul
          className="mdl-menu mdl-menu--bottom-right mdl-js-menu mdl-js-ripple-effect"
          data-mdl-for="panel-button2">
          <li className="mdl-menu__item">
            <i className="material-icons">assistant_photo</i>
            Action
          </li>
          <li className="mdl-menu__item">
            <i className="material-icons">print</i>
            Another action
          </li>
          <li className="mdl-menu__item">
            <i className="material-icons">favorite</i>
            Something else here
          </li>
        </ul>
      </div>

      <div className="card-body " id="bar-parent1">
        <div>
          <div className="form-group">
            <label for="simpleFormEmail">User Name</label>
            <input
              type="email"
              className="form-control"
              id="simpleFormEmail"
              placeholder="Enter user name"
              onChange={e => setUserName(e.target.value)}
            />
          </div>
          <div className="form-group">
            <label for="simpleFormPassword">Current Password</label>
            <input
              type="password"
              className="form-control"
              id="simpleFormPassword"
              placeholder="Current Password"
              onChange={e => setOldPassword(e.target.value)}
            />
          </div>
          <div className="form-group">
            <label for="simpleFormPassword">New Password</label>
            <input
              type="password"
              className="form-control"
              id="newpassword"
              placeholder="New Password"
              onChange={e => setNewPassword(e.target.value)}
            />
          </div>
          <button type="submit" className="btn btn-primary" onClick={handleChangePassword}>
            Submit
          </button>
        </div>
      </div>
    </Fragment>
  );
};
