import React, { Component } from "react";
import { PageHeader } from "../components/common/PageHeader";
import { Footer } from "../components/common/Footer";
import SideBar from "../components/common/SideBar";
import { AllProducts } from "../components/products/AllProducts";

class Products extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <div className="page-header-fixed sidemenu-closed-hidelogo page-content-white page-md header-white dark-sidebar-color logo-dark">
        <div className="page-wrapper">
          <PageHeader />
          <div className="page-container">
            <SideBar activeMenu='1.0'/>
            <AllProducts />
          </div>
          <Footer />
        </div>
      </div>
    );
  }
}

export default Products;
