import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { useLocation } from "react-router-dom";
import { getOrder } from "../../api/order/OrderProfile";
import { updateOrderStatus } from "../../api/order/OrderProfile";
import { OrderedProducts } from "./OrderedProducts";
import swal from "sweetalert";
import { PaginationComponent } from "../common/Pagination";

const pageSize = 10;

export const ViewOrder = (props) => {
  const location = useLocation();
  const [tableData, setTableData] = useState([]);
  const [otherData, setOtherData] = useState({});
  const [current, setCurrent] = useState(1);
  const [totalPage, setTotalPage] = useState(0);
  const [minIndex, setMinIndex] = useState(0);
  const [maxIndex, setMaxIndex] = useState(0);

  useEffect(() => {
    handleRequest(location.state.id);
  }, []);

  const handleRequest = (id) => {
    getOrder(id).then((res) => {
      if (res.data.status == "success") {
        setTableData(res.data.order.products);
        setOtherData(res.data.order);
        setTotalPage(res.data && res.data.order.products.length / pageSize);
        setMinIndex(0);
        setMaxIndex(pageSize);
      }
    });
  };

  const handlePagination = (page) => {
    setCurrent(page);
    setMinIndex((page - 1) * pageSize);
    setMaxIndex(page * pageSize);
  };

  const renderStatus = (status) => {
    switch (status) {
      case "Pending":
        return (
          <span
            className="label label-sm label-warning"
            style={{ fontSize: "20px" }}
          >
            Sent to Vendor
          </span>
        );
      case "Accepted":
        return (
          <span
            className="label label-sm label-danger"
            style={{ fontSize: "20px" }}
          >
            Accepted by Vendor
          </span>
        );
      case "Delivered":
        return (
          <span
            className="label label-sm label-success"
            style={{ fontSize: "20px" }}
          >
            Delivered
          </span>
        );
      case "Delivering":
        return (
          <span
            className="label label-sm label-warning"
            style={{ fontSize: "20px" }}
          >
            Out for Delivery
          </span>
        );

      default:
        break;
    }
  };

  const handleStatus = (id, status) => {
    let obj = {};
    if (status == "Pending") {
      obj.status = "Accepted";
    } else if (status == "Accepted") {
      obj.status = "Delivering";
    } else if (status == "Delivering") {
      obj.status = "Delivered";
    }

    swal({
      title: "Are you sure?",
      text: "Once Updated, you will not be able to recover!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        updateOrderStatus(id, obj).then((res) => {
          if (res.data.status == "success") {
            swal(`Successfully Updated from ${status} to ${obj.status} !`, {
              icon: "success",
            });
            handleRequest(location.state.id);
          }
        });
      } else {
        swal("Process Terminated!");
      }
    });
  };

  return (
    <div className="page-content-wrapper">
      <div className="page-content">
        <div className="page-bar">
          <div className="page-title-breadcrumb">
            <div className=" pull-left">
              <div className="page-title">Order Details</div>
            </div>
            <ol className="breadcrumb page-breadcrumb pull-right">
              <li>
                <i className="fa fa-home"></i>&nbsp;
                <a className="parent-item" href="index.html">
                  Home
                </a>
                &nbsp;<i className="fa fa-angle-right"></i>
              </li>
              <li>
                <a className="parent-item" href="">
                  Orders
                </a>
                &nbsp;<i className="fa fa-angle-right"></i>
              </li>
              <li className="active">View Order</li>
            </ol>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="card card-box">
              <div className="card-head">
                <header>View Order</header>
              </div>
              <div className="card-body ">
                <div className="row p-b-20">
                  {/* <!-- /.col --> */}
                  <div className="col-xl-6 col-md-8 col-12">
                    <div className="info-box bg-success">
                      <span className="info-box-icon push-bottom">
                        <i className="material-icons">monetization_on</i>
                      </span>
                      <div className="info-box-content">
                        <span
                          className="info-box-text"
                          style={{ color: "black" }}
                        >
                          Customer Details -
                        </span>
                        <span className="info-box-number">
                          {otherData?.customer?.fullName}
                        </span>
                        <br />
                        <span className="info-box-number">
                          {otherData?.customer?.email}
                        </span>
                        <br />
                        <span className="info-box-number">
                          {otherData?.customer?.phone_number}
                        </span>
                        {/* <span>$</span> */}
                        <div className="progress">
                          <div className="progress-bar width-70"></div>
                        </div>
                      </div>
                      {/* <!-- /.info-box-content --> */}
                    </div>
                    {/* <!-- /.info-box --> */}
                  </div>
                  {/* <!-- /.col --> */}

                  <div className="col-md-6 col-sm-6 col-6">
                    <div className="col-xl-3 col-md-6 col-12"></div>
                  </div>
                  <div className="col-md-6 col-sm-6 col-6">
                    <div className="btn-group">
                      {/* <Tools /> */}
                      <span
                        style={{
                          marginTop: "5px",
                          marginRight: "5px",
                          fontWeight: "bold",
                        }}
                      >
                        Click here to update :
                      </span>
                      <button
                        onClick={() =>
                          handleStatus(location.state.id, otherData.status)
                        }
                        style={{ fontSize: "20px" }}
                      >
                        {renderStatus(otherData.status)}
                      </button>
                    </div>
                  </div>
                </div>
                <div className="table-scrollable">
                  <OrderedProducts
                    tableData={tableData}
                    minIndex={minIndex}
                    maxIndex={maxIndex}
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <PaginationComponent
                    pageSize={pageSize}
                    current={current}
                    total={tableData && tableData.length}
                    onChange={handlePagination}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
