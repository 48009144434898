import React, { useState, useEffect } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import { Alert } from "antd";
import { logIn, signUp } from "../../api/user/auth";
import {
  AUTHENTICATED,
  ACCESS_TOKEN,
  GET_USER,
} from "../../redux/actions/ActionTypes";
import { authenticate } from "../../redux/actions/Auth";
import { getUser } from "../../api/user/UserProfile";
import { getCategories } from "../../api/product/ProductProfile";
import swal from "sweetalert";

export const SignupComponent = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [fullName, setfullName] = useState("");
  const [location, setLocation] = useState("");
  const [brand, setBrand] = useState("");
  const [name_of_business, setNameofBusiness] = useState("");
  const [phone_number, setPhoneNumber] = useState("");
  const [product, setProduct] = useState("");
  const [productType, setProductType] = useState("");
  const [categories, setCategories] = useState([]);
  const [subCategoty, setSubCategory] = useState([]);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [about, setAbout] = useState("");
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [errorObj, setErrorObj] = useState({});
  const [loading, setLoading] = useState(false);
  const [isRemembered, setRemember] = useState(false);
  const emailRegex = RegExp(
    '^(([^<>()\\[\\]\\\\.,;:\\s@"]+(\\.[^<>()\\[\\]\\\\.,;:\\s@"]+)*)|(".+"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$'
  );

  useEffect(() => {
    getCategories().then((res) => {
      if (res.data.status == "success") {
        setCategories(res.data.categories);
      }
    });
  }, []);

  const handleLogin = () => {
    if (
      !email ||
      !password ||
      !fullName ||
      !location ||
      !brand ||
      !product ||
      !confirmPassword ||
      !about
    ) {
      setError(true);
      setErrorMessage("Please Fill Required Fields !!!");
      swal(
        "Please fill required fields !!!",
        "You clicked the button!",
        "error"
      );
    } else {
      const obj = {
        email,
        password,
        fullName,
        location,
        brand,
        phone_number,
        name_of_business,
        product,
        about,
        isDisablePage: false,
      };
      const errors = validateForm(obj);
      if (!Object.keys(errors).length) {
        signUp(obj).then((res) => {
          if (res.data.status == "success") {
            setLoading(false);
            dispatch(authenticate(true));
            swal(
              "Created Vendor and Sent for Verification to Admin!",
              "",
              "success"
            );
            localStorage.setItem(ACCESS_TOKEN, `Bearer ${res.data.token}`);
            history.push({
              pathname: "/",
            });
            getUser().then((res) => {
              dispatch({ type: GET_USER, payload: res.data });
            });
            if (isRemembered) {
              localStorage.email = email;
              localStorage.password = password;
              localStorage.remeber = true;
            } else {
              localStorage.removeItem("email");
              localStorage.removeItem("password");
              localStorage.removeItem("remeber");
            }
          } else {
            setLoading(false);
            dispatch(authenticate(false));
            swal("Something went wrong !!!", "", "error");
          }
        });
      } else {
        setError(true);
        setErrorMessage("Please fill required fields !!!");
        swal("Please fill required fields !!!", "", "error");
        setTimeout(() => {
          setError(false);
          setErrorMessage("");
        }, 6000);
      }
    }
  };

  const validateForm = (data) => {
    let errors = {};
    if (!data.email) {
      errors.email = "Email Required !";
    } else if (!emailRegex.test(data.email)) {
      errors.email = "Invalid Email !";
    } else if (!data.password) {
      errors.password = "Password Required !";
    } else if (!data.password) {
      errors.password = "Password Required !";
    } else if (!data.fullName) {
      errors.fullName = "fullName Required !";
    } else if (!data.location) {
      errors.location = "Location Required !";
    } else if (!data.brand) {
      errors.brand = "Brand Required !";
    } else if (!data.product) {
      errors.category = "Category Required !";
    }

    return errors;
  };

  const handleCategory = (e) => {
    let newArray = categories.filter(function (item) {
      return item._id == e;
    });
    setProduct(e);
    setSubCategory(newArray[0].sub_category);
  };

  return (
    <div className="wrap-login100">
      <div className="login100-form validate-form">
        <img
          alt=""
          className="login100-form-logo"
          src="assets/img/Vendor_Vertical.png"
        />
        <span className="login100-form-title p-b-34 p-t-27">Register</span>
        <div
          className="wrap-input100 validate-input"
          data-validate="Enter fullName"
        >
          <input
            className="input100"
            type="text"
            name="fullName"
            placeholder="Full Name"
            onChange={(e) => setfullName(e.target.value)}
          />
          <span className="focus-input100" data-placeholder="&#xf207;"></span>
        </div>
        <div
          className="wrap-input100 validate-input"
          data-validate="Enter username"
        >
          <input
            className="input100"
            type="text"
            name="name_of_business"
            placeholder="Name of Business"
            onChange={(e) => setNameofBusiness(e.target.value)}
          />
          <span className="focus-input100" data-placeholder="&#xf207;"></span>
        </div>
        <div
          className="wrap-input100 validate-input"
          data-validate="Enter Location"
        >
          <input
            className="input100"
            type="text"
            name="location"
            placeholder="Location"
            onChange={(e) => setLocation(e.target.value)}
          />
          <span className="focus-input100" data-placeholder="&#xf207;"></span>
        </div>
        <div
          className="wrap-input100 validate-input"
          data-validate="Enter Email"
        >
          <input
            className="input100"
            type="text"
            name="email"
            placeholder="Email"
            onChange={(e) => setEmail(e.target.value)}
          />
          <span className="focus-input100" data-placeholder="&#xf207;"></span>
        </div>
        <div
          className="wrap-input100 validate-input"
          data-validate="Enter password"
        >
          <input
            className="input100"
            type="password"
            name="pass"
            placeholder="Password"
            onChange={(e) => setPassword(e.target.value)}
          />
          <span className="focus-input100" data-placeholder="&#xf191;"></span>
        </div>
        <div
          className="wrap-input100 validate-input"
          data-validate="Enter password Again"
        >
          <input
            className="input100"
            type="password"
            name="pass"
            placeholder="Confirm Password"
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
          <span className="focus-input100" data-placeholder="&#xf191;"></span>
        </div>
        <div
          className="wrap-input100 validate-input"
          data-validate="Enter Location"
        >
          <input
            className="input100"
            type="text"
            name="phone_number"
            placeholder="Phone Number"
            onChange={(e) => setPhoneNumber(e.target.value)}
          />
          <span className="focus-input100" data-placeholder="&#xf207;"></span>
        </div>
        <div
          className="wrap-input100 validate-input"
          data-validate="Enter Location"
        >
          <input
            className="input100"
            type="text"
            name="brand"
            placeholder="Brand Name"
            onChange={(e) => setBrand(e.target.value)}
          />
          <span className="focus-input100" data-placeholder="&#xf207;"></span>
        </div>
        <div
          className="wrap-input100 validate-input"
          data-validate="Enter About"
        >
          <input
            className="input100"
            type="text"
            name="about"
            placeholder="About"
            onChange={(e) => setAbout(e.target.value)}
          />
          <span className="focus-input100" data-placeholder="&#xf207;"></span>
        </div>
        <div
          className="wrap-input100 validate-input"
          data-validate="Enter Location"
        >
          <select
            className="input100 select"
            type="text"
            name="category"
            placeholder="Category"
            onChange={(e) => handleCategory(e.target.value)}
          >
            {categories &&
              categories.map((item, key) => {
                return <option value={item._id}> {item.name}</option>;
              })}
          </select>
          <span className="focus-input100" data-placeholder="&#xf207;"></span>
        </div>
        <div className="contact100-form-checkbox">
          <input
            className="input-checkbox100"
            id="ckb1"
            type="checkbox"
            name="remember-me"
            checked={isRemembered}
            onChange={(e) => setRemember(!isRemembered)}
          />
          <label className="label-checkbox100" for="ckb1">
            Remember me
          </label>
        </div>
        <div className="container-login100-form-btn">
          <button className="login100-form-btn" onClick={handleLogin}>
            Signup
          </button>
        </div>
        {error && (
          <div className="error-login">
            <span className="error-text">{errorMessage}</span>
          </div>
        )}
        <div className="text-center p-t-90">
          <Link className="txt1" to="/">
            Login
          </Link>
        </div>
      </div>
    </div>
  );
};

export default connect()(SignupComponent);
