import React, { useEffect, useState } from "react";
import { Link } from 'react-router-dom';
import { getAllOrders } from "../../api/order/OrderProfile";
import moment from 'moment';

export const LastestOrderDetailsComponent = (props) => {
  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    getAllOrders().then((res) => {
      if (res.data.status == "success") {
        handleLatestOrders(res.data.orders);
      } else {
      }
    });
  }, []);

  const handleLatestOrders = (data) => {
    let newArr = [];
    data.forEach((element) => {
      if (element.status == "Pending") {
        newArr.push(element);
      }
    });
    setTableData(newArr);
  };

  return (
    <div className="row">
      <div className="col-md-12 col-sm-12">
        <div className="card  card-box">
          <div className="card-head">
            <header>Latest Orders</header>
            <div className="tools">
              <a
                className="fa fa-repeat btn-color box-refresh"
                href="javascript:;"></a>
              <a
                className="t-collapse btn-color fa fa-chevron-down"
                href="javascript:;"></a>
              <a
                className="t-close btn-color fa fa-times"
                href="javascript:;"></a>
            </div>
          </div>
          <div className="card-body ">
            <div className="table-wrap">
              <div className="table-responsive">
                <table
                  className="table display product-overview mb-30"
                  id="support_table5">
                  <thead>
                    <tr>
                      <th>No</th>
                      <th>Name</th>
                      <th>Phone</th>
                      <th>Amount (KWD)</th>
                      <th>Created Date</th>
                      <th>Status</th>
                      {/* <th>Room Type</th> */}
                      <th>Update Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {tableData?.map((item, key) => {
                      return (
                        <tr>
                          <td>{key + 1}</td>
                          <td>{item?.customer?.fullName}</td>
                          <td>{item?.customer?.phone_number}</td>
                          <td>{item?.amount.toFixed(2)}</td>
                          <td>{moment(item?.date).format("YYYY-MM-DD")}</td>
                          <td>
                            <span className="label label-sm label-warning">
                              Pending
                            </span>
                          </td>
                          <td>
                            <Link
                              to={{
                                pathname: "/order-detail",
                                state: {
                                  id: item && item._id,
                                  currentStatus: item && item?.status 
                                } 
                              }}
                              className="btn btn-tbl-edit btn-xs">
                              <i className="fa fa-pencil"></i>
                            </Link>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
