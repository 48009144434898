import React, { Component } from 'react';
import { Footer } from '../components/common/Footer';
import { PageHeader } from '../components/common/PageHeader';
import SideBar from '../components/common/SideBar';
import { AddProduct } from '../components/products/AddProduct';

class AddNewProduct extends Component {
    constructor(props) {
        super(props);
        this.state = {  }
    }
    render() { 
        return ( 
            <div class="page-header-fixed sidemenu-closed-hidelogo page-content-white page-md header-white dark-sidebar-color logo-dark">
                <div class="page-wrapper">
                    <PageHeader />
                    <div class="page-container">
                        <SideBar activeMenu='1.1'/>
                        <AddProduct />
                    </div>
                    <Footer />
                </div>
            </div>
         );
    }
}
 
export default AddNewProduct;