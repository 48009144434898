import axios from "axios";

export const getAllCustomers = (params = {}) => {
  return axios.get(
    `${process.env.REACT_APP_API_URL}/vendor/customers`,
    {
      headers: {
        Authorization: localStorage.access_token,
      },
    },
    params
  );
};