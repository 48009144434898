import React, { useState } from "react";
import swal from "sweetalert";
import { forgetPassword } from "../../api/user/auth";

export const ForgetPasswordComponent = (props) => {
  const [email, setEmail] = useState("");

  const handleForgetPassword = () => {
    if (!email) {
        swal("Please Enter Your Email !", "", "error");
    } else if (!/\S+@\S+\.\S+/.test(email)) {
        swal("Invalid Email !", "", "error");
    } else {
        forgetPassword({ email }).then(res => {

        })
    }
  }

  return (
    <div className="wrap-login100">
      <div className="login100-form validate-form">
        <span className="login100-form-title p-b-34 p-t-27">
          Forget Password
        </span>
        <div
          className="wrap-input100 validate-input"
          data-validate="Enter username"
        >
          <input
            className="input100"
            type="text"
            name="email"
            placeholder="Email"
            onChange={(e) => setEmail(e.target.value)}
          />
          <span className="focus-input100" data-placeholder="&#xf207;"></span>
        </div>
        <div className="container-login100-form-btn">
          <button className="login100-form-btn" onClick={handleForgetPassword}>
            Submit
          </button>
        </div>
      </div>
    </div>
  );
};
