import React, { useEffect, useState } from "react";
import { getUser, updateLocation } from "../../api/user/UserProfile";

export const ShippingUpdateComponent = (props) => {
  const [location, setLocation] = useState("");
  const [defaultLocation, setDefaultLocation] = useState("");
  const [message, setMessage] = useState("");

  useEffect(() => {
    getUser().then((res) => {
      setDefaultLocation(res.data.vendor.location);
    });
  }, []);

  const handleLocation = () => {
    let obj = {
      location: location ? location : defaultLocation,
    };
    updateLocation(obj).then((res) => {
      if (res.data.status == 'success') {
        setMessage(res.data.message);
      }
    });
  };

  return (
    <div className="page-content-wrapper">
      <div className="page-content">
        <div className="page-bar">
          <div className="page-title-breadcrumb">
            <div className=" pull-left">
              <div className="page-title">Update Shipping Details</div>
            </div>
            <ol className="breadcrumb page-breadcrumb pull-right">
              <li>
                <i className="fa fa-home"></i>&nbsp;
                <a className="parent-item" href="index.html">
                  Home
                </a>
                &nbsp;<i className="fa fa-angle-right"></i>
              </li>
              <li>
                <a className="parent-item" href="">
                  Shipping Settings
                </a>
                &nbsp;<i className="fa fa-angle-right"></i>
              </li>
              <li className="active">Update Shipping Details</li>
            </ol>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12">
            <div className="card-box">
              <div className="card-body row">
                <div className="col-lg-6 p-t-20">
                  <label className="mdl-textfield__label">
                    Shipping Address
                  </label>
                  <div className="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width">
                    <input
                      className="mdl-textfield__input"
                      type="text"
                      id="txtModeltName"
                      defaultValue={defaultLocation ? defaultLocation : ""}
                      onChange={(e) => setLocation(e.target.value)}
                    />
                  </div>
                </div>
                <div className="col-lg-12 p-t-20 text-left">
                  <button
                    type="button"
                    className="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect m-b-10 m-r-20 btn-pink"
                    onClick={handleLocation}>
                    Update
                  </button>
                  <button
                    type="button"
                    className="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect m-b-10 btn-default">
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
