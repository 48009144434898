import React, { Component } from 'react';
import { Footer } from "../components/common/Footer";
import { PageHeader } from "../components/common/PageHeader";
import SideBar from "../components/common/SideBar";
import { TransactionList } from '../components/Transaction/TransactionList';


class Transactions extends Component {
    constructor(props) {
        super(props);
        this.state = {  }
    }
    render() { 
        return ( 
            <div className="page-header-fixed sidemenu-closed-hidelogo page-content-white page-md header-white dark-sidebar-color logo-dark">
        <div className="page-wrapper">
          <PageHeader />
          <div className="page-container">
            <SideBar activeMenu='6.0' />
            <TransactionList />
          </div>
          <Footer />
        </div>
      </div>
         );
    }
}
 
export default Transactions;