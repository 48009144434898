import React, { Component } from "react";
import { SignupComponent } from "../components/auth/SignupComponent";

class Signup extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <div className="limiter">
        <div className="container-login100 page-background">
            <SignupComponent />
        </div>
      </div>
    );
  }
}

export default Signup;