import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { getUser } from "../../api/user/UserProfile";
import { AUTHENTICATED, ACCESS_TOKEN, GET_USER } from "../../redux/actions/ActionTypes";
import { authenticate } from "../../redux/actions/Auth";

class SideBar extends Component {
  menuList = [
    {key: 0, mainMenu: 'Dashboard', iconName: 'dashboard', page: '/dashboard', active: true},
    {key: 1, mainMenu: 'Products', iconName: 'business_center', page: '', active: true, subMenu: [{key: 1.0, subMenuName: 'View Products', page: '/products'}, {key: 1.1, subMenuName: 'Add New Product', page: '/add-product'}]},
    {key: 2, mainMenu: 'Profile', iconName: 'person', page: '', active: true, subMenu: [{key: 2.0, subMenuName: 'General Information', page: '/user-profile'}]},
    {key: 3, mainMenu: 'Shipping Settings', iconName: 'local_shipping', page: '', active: true, subMenu: [{key: 3.0, subMenuName: 'Update Shiping Details', page: '/update-shipping'}, {key: 3.1, subMenuName: 'Shipping Methods', page: '/shipping-method'}]},
    {key: 4, mainMenu: 'Orders', iconName: 'payment', page: '', active: true, subMenu: [{key: 4.0, subMenuName: 'View Orders', page: '/orders'}, {key: 4.1, subMenuName: 'Pending Orders', page: '/update-orders'}]},
    {key: 5, mainMenu: 'Customers', iconName: 'person', page: '/customers', active: true},
    {key: 6, mainMenu: 'Transactions', iconName: 'payment', page: '', active: true, subMenu: [{key: 6.0, subMenuName: 'All Transactions', page: '/transactions'}]},
  ];

  setActive = function (activeMenu) {
    let mainMenuIndex = activeMenu ? Number(activeMenu.split('.')[0]) : 0;
    let subMenuIndex = activeMenu ? Number(activeMenu.split('.')[1]) : 0

    this.menuList.forEach(element => {
      element.active = false;

      if (element.subMenu) {
        element.subMenu.forEach(sub => {
          sub.active = false;
        });
      }
    });

    this.menuList[mainMenuIndex].active = true;

    if (this.menuList[mainMenuIndex].subMenu) {
      this.menuList[mainMenuIndex].subMenu[subMenuIndex].active = true;
    }
  }

  constructor(props) {
    super(props);
    this.setActive(props.activeMenu);
    this.state = {
      userDetails: {},
      menuList: this.menuList
    };
  }

  componentDidMount() {
    // getUser().then(res => {
    //   this.setState({ userDetails: res.data.vendor });
    // })
  }

  handleLogout = () => {
    this.props.dispatch(authenticate(false));
    localStorage.setItem(ACCESS_TOKEN, "");
  }

  render() {
    const { userDetails } = this.state;
    const { menuList } = this.state;
    const { user } = this.props;
    console.log("user", menuList);
    return (
      <div className="sidebar-container">
        <div className="sidemenu-container navbar-collapse collapse fixed-menu">
          <div id="remove-scroll">
            <ul
              className="sidemenu page-header-fixed p-t-20"
              data-keep-expanded="false"
              data-auto-scroll="true"
              data-slide-speed="200">
              <li className="sidebar-toggler-wrapper hide">
                <div className="sidebar-toggler">
                  <span></span>
                </div>
              </li>
              <li className="sidebar-user-panel">
                <div className="user-panel">
                  {/* <div className="row">
                    <div className="sidebar-userpic">
                      <img
                        src="assets/img/dp.jpg"
                        className="img-responsive"
                        alt=""
                      />{" "}
                    </div>
                  </div> */}
                  <div className="profile-usertitle">
                    {/* <div className="sidebar-userpic-name"> {user?.vendor?.name_of_business} </div> */}
                    {/* <div className="profile-usertitle-job"> Manager </div> */}
                  </div>
                  <div className="sidebar-userpic-btn">
                    {/* <a
                      className="tooltips"
                      href="user_profile.html"
                      data-placement="top"
                      data-original-title="Profile">
                      <i className="material-icons">person_outline</i>
                    </a>
                    <a
                      className="tooltips"
                      href="email_inbox.html"
                      data-placement="top"
                      data-original-title="Mail">
                      <i className="material-icons">mail_outline</i>
                    </a>
                    <a
                      className="tooltips"
                      href="chat.html"
                      data-placement="top"
                      data-original-title="Chat">
                      <i className="material-icons">chat</i>
                    </a> */}
                    {/* <Link
                      className="tooltips"
                      to="/"
                      data-placement="top"
                      data-original-title="Logout">
                      <i className="material-icons" onClick={() => this.handleLogout()}>input</i>
                    </Link> */}
                  </div>
                </div>
              </li>
              
              {
                menuList.map(item => {
                  return (
                    <li key={item.key} className={item.active ? 'nav-item active' : 'nav-item'}>
                      <a href={item.page} className="nav-link nav-toggle">
                        <i className="material-icons">{item.iconName}</i>
                        <span className="title">{item.mainMenu}</span>
                        {item.subMenu && <span className="arrow"></span>}
                      </a>

                      {item.subMenu && 
                        <ul className="sub-menu">
                          {
                            item.subMenu.map(subItem => {
                              return (
                                <li key={subItem.key} className={subItem.active ? 'nav-item active' : 'nav-item'}>
                                  <a href={subItem.page} className="nav-link ">
                                    <span className="title">{subItem.subMenuName}</span>
                                  </a>
                                </li>
                              );
                            })
                          }
                        </ul>
                      }
                    </li>
                  )
                })
              }
            </ul>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  user: state.user.user,
});
export default connect(mapStateToProps)(SideBar);