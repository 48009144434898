import React, { Component } from "react";
import { ForgetPasswordComponent } from '../components/auth/ForgetPassworComponent';

class ForgetPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <div className="limiter">
        <div className="container-login100 page-background">
            <ForgetPasswordComponent />
        </div>
      </div>
    );
  }
}

export default ForgetPassword;
