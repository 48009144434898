import React, { useState, useEffect } from "react";
import { ChartComponent } from "./Chart";
import { LastestOrderDetailsComponent } from "./LatestOrderDetails";
import { ProductStatusComponent } from "./ProductStatus";
import { ChatSideBar } from "../common/ChatSideBar";
import { getDashboardData } from "../../api/dashboard/DashboardProfile";
import { getUser } from "../../api/user/UserProfile";
import { Fragment } from "react";

export const Index = (props) => {
  const [totalOrders, setTotalOrders] = useState();
  const [todayOrders, setTodayOrders] = useState();
  const [soldProducts, setSoldProducts] = useState();
  const [pendingOrders, setPendingOrders] = useState();

  useEffect(() => {
    getUser().then((res) => {
      if (res.data.status == "success") {
        getDashboardData(res.data.vendor._id).then((res) => {
          if (res.data.status == "success") {
            setTotalOrders(res.data.orderCount);
            setTodayOrders(res.data.orderCountToday);
            setSoldProducts(res.data.orderList);
            setPendingOrders(res.data.pendingOrders && res.data.pendingOrders);
          }
        });
      }
    });
  }, []);
  return (
    <Fragment>
      <div className="page-content-wrapper">
        <div className="page-content">
          {/* BreadCrumb - starts */}
          <div className="page-bar">
            <div className="page-title-breadcrumb">
              <div className=" pull-left">
                <div className="page-title">Dashboard</div>
              </div>
              <ol className="breadcrumb page-breadcrumb pull-right">
                <li>
                  <i className="fa fa-home"></i>&nbsp;
                  <a className="parent-item" href="index.html">
                    Home
                  </a>
                  &nbsp;<i className="fa fa-angle-right"></i>
                </li>
                <li className="active">Dashboard</li>
              </ol>
            </div>
          </div>
          {/* BreadCrumb - ends */}

          {/* Widget - Start */}
          <div className="state-overview">
            <div className="row">
              <div className="col-xl-3 col-md-6 col-12">
                <div className="info-box bg-blue">
                  <span className="info-box-icon push-bottom">
                    <i className="material-icons">style</i>
                  </span>
                  <div className="info-box-content">
                    <span className="info-box-text">Total Orders Placed</span>
                    <span className="info-box-number">
                      {totalOrders ? totalOrders : "0"}
                    </span>
                    <div className="progress">
                      <div className="progress-bar width-60"></div>
                    </div>
                  </div>
                  {/* <!-- /.info-box-content --> */}
                </div>
                {/* <!-- /.info-box --> */}
              </div>
              {/* <!-- /.col --> */}
              <div className="col-xl-3 col-md-6 col-12">
                <div className="info-box bg-success">
                  <span className="info-box-icon push-bottom">
                    <i className="material-icons">monetization_on</i>
                  </span>
                  <div className="info-box-content">
                    <span className="info-box-text">Today Orders</span>
                    <span className="info-box-number">
                      {todayOrders ? todayOrders : "0"}
                    </span>
                    {/* <span>$</span> */}
                    <div className="progress">
                      <div className="progress-bar width-60"></div>
                    </div>
                  </div>
                  {/* <!-- /.info-box-content --> */}
                </div>
                {/* <!-- /.info-box --> */}
              </div>
              {/* <!-- /.col --> */}
              <div className="col-xl-3 col-md-6 col-12">
                <div className="info-box bg-orange">
                  <span className="info-box-icon push-bottom">
                    <i className="material-icons">card_travel</i>
                  </span>
                  <div className="info-box-content">
                    <span className="info-box-text">Total Pending Orders</span>
                    <span className="info-box-number">
                      {pendingOrders && pendingOrders ? pendingOrders : "0"}
                    </span>
                    {/* <span>$</span> */}
                    <div className="progress">
                      <div className="progress-bar width-40"></div>
                    </div>
                  </div>
                  {/* <!-- /.info-box-content --> */}
                </div>
                {/* <!-- /.info-box --> */}
              </div>
              {/* <!-- /.col --> */}
              <div className="col-xl-3 col-md-6 col-12">
                <div className="info-box bg-purple">
                  <span className="info-box-icon push-bottom">
                    <i className="material-icons">check_circle</i>
                  </span>
                  <div className="info-box-content">
                    <span className="info-box-text">Total Products Sold</span>
                    <span className="info-box-number">
                      {soldProducts && soldProducts ? soldProducts : "0"}
                    </span>
                    <div className="progress">
                      <div className="progress-bar width-80"></div>
                    </div>
                  </div>
                  {/* <!-- /.info-box-content --> */}
                </div>
                {/* <!-- /.info-box --> */}
              </div>
              {/* <!-- /.col --> */}
            </div>
          </div>
          {/* Widget - End  */}

          {/* Chart - Start */}
          {/* <ChartComponent /> */}
          {/* Chart - End */}

          {/* Products Status - Start */}
          {/* <ProductStatusComponent /> */}
          {/* Products Status - End */}

          {/* Latest Order Details - Start */}
          <LastestOrderDetailsComponent />
          {/* Latest Order Details - End */}
          <ChatSideBar />
        </div>
      </div>
    </Fragment>
  );
};
