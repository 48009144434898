import React from "react";

export const ShippingMethodComponent = (props) => {
  return (
    <div className="page-content-wrapper">
      <div className="page-content">
        <div className="page-bar">
          <div className="page-title-breadcrumb">
            <div className=" pull-left">
              <div className="page-title">Shipping Methods</div>
            </div>
            <ol className="breadcrumb page-breadcrumb pull-right">
              <li>
                <i className="fa fa-home"></i>&nbsp;
                <a className="parent-item" href="index.html">
                  Home
                </a>
                &nbsp;<i className="fa fa-angle-right"></i>
              </li>
              <li>
                <a className="parent-item" href="">
                  Shipping Settings
                </a>
                &nbsp;<i className="fa fa-angle-right"></i>
              </li>
              <li className="active">Shipping Methods</li>
            </ol>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12">
            <div className="card-box">
              <div className="card-head">
                <header>Select Shipping Methods</header>
              </div>
              <div className="card-body row">
                <div className="col-lg-12 p-t-20">
                  <div className="form-group">
                    <div className="checkbox checkbox-icon-black p-0">
                      <input id="checkbox1" type="checkbox" />
                      <label for="checkbox1">Method 1</label>
                    </div>
                    <div className="checkbox checkbox-icon-black p-0">
                      <input id="checkbox2" type="checkbox" checked="checked" />
                      <label for="checkbox2">Method 2</label>
                    </div>
                    <div className="checkbox checkbox-icon-black p-0">
                      <input id="checkbox3" type="checkbox" disabled />
                      <label for="checkbox3">Method 3</label>
                    </div>
                  </div>
                </div>
                <div className="col-lg-12 p-t-20">
                  <label className="control-label bold">
                    Select Allowed Countries
                  </label>
                  <div className="form-group row">
                    <div className="col-lg-6 p-t-20">
                      <label
                        className="mdl-radio mdl-js-radio mdl-js-ripple-effect"
                        for="option-1">
                        <input
                          type="radio"
                          id="option-1"
                          className="mdl-radio__button"
                          name="options"
                          value="1"
                          checked
                        />
                        <span className="mdl-radio__label">All Countries</span>
                      </label>
                    </div>

                    <div className="col-lg-6 p-t-20">
                      <select
                        id="multiple"
                        className="form-control select2-multiple"
                        multiple>
                        <optgroup label="Alaskan/Hawaiian Time Zone">
                          <option value="AK">Alaska</option>
                          <option value="HI">Hawaii</option>
                        </optgroup>
                        <optgroup label="Pacific Time Zone">
                          <option value="CA">California</option>
                          <option value="NV">Nevada</option>
                          <option value="OR">Oregon</option>
                          <option value="WA">Washington</option>
                        </optgroup>
                        <optgroup label="Mountain Time Zone">
                          <option value="AZ">Arizona</option>
                          <option value="CO">Colorado</option>
                          <option value="ID">Idaho</option>
                          <option value="MT">Montana</option>
                          <option value="NE">Nebraska</option>
                          <option value="NM">New Mexico</option>
                          <option value="ND">North Dakota</option>
                          <option value="UT">Utah</option>
                          <option value="WY">Wyoming</option>
                        </optgroup>
                        <optgroup label="Central Time Zone">
                          <option value="AL">Alabama</option>
                          <option value="AR">Arkansas</option>
                          <option value="IL">Illinois</option>
                          <option value="IA">Iowa</option>
                          <option value="KS">Kansas</option>
                          <option value="KY">Kentucky</option>
                          <option value="LA">Louisiana</option>
                          <option value="MN">Minnesota</option>
                          <option value="MS">Mississippi</option>
                          <option value="MO">Missouri</option>
                          <option value="OK">Oklahoma</option>
                          <option value="SD">South Dakota</option>
                          <option value="TX">Texas</option>
                          <option value="TN">Tennessee</option>
                          <option value="WI">Wisconsin</option>
                        </optgroup>
                        <optgroup label="Eastern Time Zone">
                          <option value="CT">Connecticut</option>
                          <option value="DE">Delaware</option>
                          <option value="FL">Florida</option>
                          <option value="GA">Georgia</option>
                          <option value="IN">Indiana</option>
                          <option value="ME">Maine</option>
                          <option value="MD">Maryland</option>
                          <option value="MA">Massachusetts</option>
                          <option value="MI">Michigan</option>
                          <option value="NH">New Hampshire</option>
                          <option value="NJ">New Jersey</option>
                          <option value="NY">New York</option>
                          <option value="NC">North Carolina</option>
                          <option value="OH">Ohio</option>
                          <option value="PA">Pennsylvania</option>
                          <option value="RI">Rhode Island</option>
                          <option value="SC">South Carolina</option>
                          <option value="VT">Vermont</option>
                          <option value="VA">Virginia</option>
                          <option value="WV">West Virginia</option>
                        </optgroup>
                      </select>
                    </div>
                  </div>
                </div>

                <div className="col-lg-6 p-t-20">
                  <div className="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width">
                    <input
                      className="mdl-textfield__input"
                      type="text"
                      id="vehiclename"
                    />
                    <label className="mdl-textfield__label">
                      Shipping Rate
                    </label>
                  </div>
                </div>
                <div className="col-lg-12 p-t-20">
                  <label className="control-label col-md-3">
                    Export Details from CSV
                  </label>
                  <form id="id_dropzone" className="dropzone">
                    <div className="dz-message">
                      <div className="dropIcon">
                        <i className="material-icons">cloud_upload</i>
                      </div>
                      <h3>Drop files here or click to upload.</h3>
                    </div>
                  </form>
                </div>

                <div className="col-lg-12 p-t-20 text-left">
                  <button
                    type="button"
                    className="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect m-b-10 m-r-20 btn-pink">
                    Save
                  </button>
                  <button
                    type="button"
                    className="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect m-b-10 btn-default">
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
