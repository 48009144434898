import React, { useState } from "react";

export const TransactionDetails = (props) => {
    const [IP, setIP] = useState("");
    const [taxID, setTaxID] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [currency, setCurrency] = useState("");
    const [date1, setDate1] = useState("");
    const [date2, setDate2] = useState("");

    const handleSave = () => {
        let obj = {
            IP,
            taxID,
            phoneNumber,
            currency,
            date1,
            date2
        };
    }

  return (
    <div className="white-box">
      <div className="page-content-wrapper">
          <div className="row">
            <div className="col-md-12 col-sm-12">
              <div className="">
                <div className="card-head">
                  <header>Transaction Information</header>
                </div>
                <div className="card-body row">
                  <div className="col-lg-12 p-t-20">
                    <div className="form-group row">
                      <label className="col-sm-2 control-label">Order related transactions</label>
                      <div className="col-sm-10">
                        <input
                          type="text"
                          placeholder=""
                          data-mask="999.999.999.9999"
                          className="form-control"
                          onChange={e => setIP(e.target.value)}
                        />
                        {/* <span className="help-block">192.168.110.310</span> */}
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-sm-2 control-label">Commission related transactions</label>
                      <div className="col-sm-10">
                        <input
                          type="text"
                          placeholder=""
                          data-mask="99-9999999"
                          className="form-control"
                          onChange={e => setTaxID(e.target.value)}
                        />
                        {/* <span className="help-block">99-9999999</span> */}
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-12 p-t-20 left">
                    <button
                      type="button"
                      className="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect m-b-10 m-r-20 btn-pink"
                      onClick={handleSave}
                      >
                      Save
                    </button>
                    <button
                      type="button"
                      className="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect m-b-10 btn-default">
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
      </div>
    </div>
  );
};
