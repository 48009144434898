import React, { useEffect, useState, Fragment } from "react";
import { useParams, useLocation, useHistory } from "react-router-dom";
import { Select, Checkbox } from "antd";
import { FileUpload } from "./FileUpload";
import TagsInput from "react-tagsinput";
import Dropzone from "react-dropzone-uploader";
import "react-tagsinput/react-tagsinput.css";
import { editProduct } from "../../api/product/ProductProfile";
import { createProduct, getCategories } from "../../api/product/ProductProfile";
import swal from "sweetalert";

const { Option } = Select;

const prodTypeArr = ["1", "2", "3", "4", "5", "6", "7", "8"];
const KeyCodes = {
  comma: 188,
  enter: 13,
};

export const EditProductsComponent = (props) => {
  let location = useLocation();
  const history = useHistory();
  let productParams = location.state;

  const [product, setProduct] = useState(
    productParams && productParams.category
  );
  const [productName, setProductName] = useState("");
  const [productType, setProductType] = useState(
    productParams && productParams.sub_category
  );
  const [productDescription, setProductDiscription] = useState();
  const [sizeTags, setSizeTags] = useState(productParams && productParams.sizes);
  const [colortags, setColorTags] = useState(
    productParams && productParams.colors
  );
  const [materialTags, setMaterials] = useState(
    productParams && productParams.material_type
  );
  const [price, setPrice] = useState();
  const [stock, setStock] = useState();
  const [shippingFee, setShippingFee] = useState();
  const [fileList, setFileList] = useState([]);
  const [uploadedImages, setUploadedImages] = useState(
    productParams && productParams.images
  );
  const [categories, setCategories] = useState([]);
  const [subCategoty, setSubCategory] = useState([]);
  const [visible, setVisible] = useState(false);
  const [message, setMessage] = useState("");
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [arFileList, setArFileList] = useState([]);
  const [arChecked, setArChecked] = useState(
    productParams && productParams.ar_enabled
  );
  const [uploadedArImages, setUploadedArImages] = useState(
    productParams && productParams.ar_images
  );

  useEffect(() => {
    getCategories().then((res) => {
      if (res.data.status == "success") {
        setCategories(res.data.categories);
        handleSubCategoriesToEdit(res.data.categories);
      }
    });
  }, []);

  const handleSubCategoriesToEdit = (data) => {
    let newArray = data?.filter((item) => {
      return item._id == productParams?.category
    });
    setSubCategory(newArray[0].sub_category);
  }

  const handleCategory = (e) => {
    let newArray = categories.filter((item) => {
      return item._id == e;
    });
    setProduct(e);
    setSubCategory(newArray[0].sub_category);
  };

  const handleSizeTag = (tag) => {
    setSizeTags(tag);
  };

  const handleColorTag = (tag) => {
    setColorTags(tag);
  };

  const handleMaterial = (tag) => {
    setMaterials(tag);
  };

  const getUploadParams = ({ meta }) => {
    return { url: "https://httpbin.org/post" };
  };

  const getUploadParamsAR = ({ meta }) => {
    return { url: "https://httpbin.org/post" };
  };

  // called every time a file's `status` changes
  const handleChangeStatus = ({ meta, file }, status) => {
    if (status == "done") {
      FileUpload(file, (res) => {
        fileList.push(res.location);
        setFileList(fileList);
      });
    }
  };

  const handleChangeStatusAR = ({ meta, file }, status) => {
    if (status == "done") {
      FileUpload(file, (res) => {
        arFileList.push(res.location);
        setArFileList(arFileList);
      });
    }
  };

  const handleSubmit = () => {
    let obj = {
      name: productName ? productName : productParams.name,
      description: productDescription
        ? productDescription
        : productParams.description,
      category: product ? product : productParams.description,
      sub_category: productType ? productType : productParams.description,
      colors: colortags ? colortags : productParams.colors,
      sizes: sizeTags ? sizeTags : productParams.sizes,
      images: fileList ? fileList : productParams.images,
      stock: stock ? stock : productParams.stock,
      amount: price ? price : productParams.amount,
      shippingFee: shippingFee ? shippingFee : productParams.shippingFee,
      ar_enabled: arChecked
        ? arChecked
        : productParams && productParams.ar_enabled,
      ar_images: arFileList
        ? arFileList
        : productParams && productParams.ar_images,
      material_type: materialTags
        ? materialTags
        : productParams && productParams.material_type,
    };

    editProduct(productParams._id, obj).then((res) => {
      if (res.data.status == "success") {
        setLoading(false);
        setError(false);
        setMessage("Successfully submitted to admin ...");
        history.push({
          pathname: "/products",
        });
        swal("Successfully Submitted!", "", "success");
      } else {
        setLoading(false);
        setError(true);
        setMessage("Something went wrong!");
      }
    });
  };
  let selectedProduct = categories.filter((option) => option._id == product);

  const handleCancel = () => {};

  const handleAr = (e) => {
    setArChecked(e.target.checked);
  };

  return (
    <div className="page-content-wrapper">
      <div className="page-content">
        <div className="page-bar">
          <div className="page-title-breadcrumb">
            <div className=" pull-left">
              <div className="page-title">Edit Product Details</div>
            </div>
            <ol className="breadcrumb page-breadcrumb pull-right">
              <li>
                <i className="fa fa-home"></i>&nbsp;
                <a className="parent-item" href="index.html">
                  Home
                </a>
                &nbsp;<i className="fa fa-angle-right"></i>
              </li>
              <li className="active">Edit Product Details</li>
            </ol>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12">
            <div className="card-box">
              <div className="card-head">
                <header>Edit Product Details</header>
                <button
                  id="panel-button"
                  className="mdl-button mdl-js-button mdl-button--icon pull-right"
                  data-upgraded=",MaterialButton"
                >
                  <i className="material-icons">more_vert</i>
                </button>
                <ul
                  className="mdl-menu mdl-menu--bottom-right mdl-js-menu mdl-js-ripple-effect"
                  data-mdl-for="panel-button"
                >
                  <li className="mdl-menu__item">
                    <i className="material-icons">assistant_photo</i>Action
                  </li>
                  <li className="mdl-menu__item">
                    <i className="material-icons">print</i>Another action
                  </li>
                  <li className="mdl-menu__item">
                    <i className="material-icons">favorite</i>Something else
                    here
                  </li>
                </ul>
              </div>
              <div className="card-body row">
                <div className="col-lg-6 p-t-20">
                  <label className="mdl-textfield__label">Product Name</label>
                  <div className="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width">
                    <input
                      defaultValue={
                        productParams && productParams.name
                          ? productParams.name
                          : "Product Name"
                      }
                      className="mdl-textfield__input"
                      type="text"
                      id="txtLasttName"
                      onChange={(e) => setProductName(e.target.value)}
                    />
                  </div>
                </div>
                <div className="col-lg-6 p-t-20">
                  <label for="sample2" className="mdl-textfield__label">
                    Choose Category
                  </label>
                  <div className="mdl-textfield mdl-js-textfield mdl-textfield--floating-label getmdl-select getmdl-select__fix-height txt-full-width">
                    <select
                      onChange={(e) => handleCategory(e.target.value)}
                      className="mdl-textfield__input"
                      defaultValue={selectedProduct[0]}
                      value={product}
                    >
                      <option />
                      {categories.map((item, key) => {
                        return (
                          <option
                            value={item._id}
                            key={key}
                            className="mdl-menu__item"
                          >
                            {item.name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
                <div className="col-lg-6 p-t-20">
                  <label for="list2" className="mdl-textfield__label">
                    Product Type
                  </label>
                  <div className="mdl-textfield mdl-js-textfield mdl-textfield--floating-label getmdl-select getmdl-select__fix-height txt-full-width">
                    <select
                      onChange={(e) => setProductType(e.target.value)}
                      className="mdl-textfield__input"
                      value={productType}
                    >
                      <option />
                      {subCategoty?.map((item, key) => {
                        return (
                          <option key={key} className="mdl-menu__item">
                            {item}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>

                <div className="col-lg-12 p-t-20">
                  <label className="mdl-textfield__label" for="text7">
                    Product Description
                  </label>
                  <div className="mdl-textfield mdl-js-textfield txt-full-width">
                    <textarea
                      defaultValue={productParams && productParams.description}
                      className="mdl-textfield__input"
                      rows="4"
                      onChange={(e) => setProductDiscription(e.target.value)}
                    ></textarea>
                  </div>
                </div>

                <div className="col-lg-6 p-t-20">
                  <label className="mdl-textfield__label">
                    Remaining Stock
                  </label>
                  <div className="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width">
                    <input
                      defaultValue={productParams && productParams.stock}
                      className="mdl-textfield__input"
                      type="text"
                      id="txtCity"
                      onChange={(e) => setStock(e.target.value)}
                    />
                  </div>
                </div>

                <div className="col-lg-12 p-t-20">
                  {uploadedArImages.map((item, index) => {
                    return (
                      <img
                        src={item}
                        style={{ height: "100px", width: "100px" }}
                      />
                    );
                  })}
                </div>

                <div className="col-lg-12 p-t-20">
                  <Checkbox onChange={handleAr}>AR Enable</Checkbox>
                </div>
                {arChecked ? (
                  <div className="col-lg-12 p-t-20">
                    <label className="control-label col-md-3">
                      Upload AR Photo
                    </label>
                    {/* <div id="id_dropzone" className="dropzone" >
                  <input type="file" id="files" name="files" multiple onChange={handleUpload} />
                    <div className="dz-message">
                      <div className="dropIcon">
                        <i className="material-icons">cloud_upload</i>
                      </div>
                      <h3>Drop files here or click to upload.</h3>
                    </div>
                  </div> */}
                    <Dropzone
                      getUploadParams={getUploadParamsAR}
                      onChangeStatus={handleChangeStatusAR}
                      // onSubmit={handleUpload}
                      accept="image/*"
                      classNames="dropzone dz-message"
                      inputContent="Drop files here or click to upload."
                    />
                  </div>
                ) : (
                  <Fragment />
                )}

                <div className="col-lg-12 p-t-20">
                  {uploadedImages.map((item, index) => {
                    return (
                      <img
                        src={item}
                        style={{ height: "100px", width: "100px" }}
                      />
                    );
                  })}
                </div>

                <div className="col-lg-12 p-t-20">
                  <label className="control-label col-md-3">Upload Photo</label>
                  <Dropzone
                    getUploadParams={getUploadParams}
                    onChangeStatus={handleChangeStatus}
                    // onSubmit={handleUpload}
                    accept="image/*"
                    classNames="dropzone dz-message"
                    inputContent="Drop files here or click to upload."
                  />
                </div>
                <div className="col-lg-6 p-t-20">
                  <header>Variant Details</header>
                  <form>
                    <div className="form-group">
                      <label className="control-label">Size:</label>
                      <TagsInput value={sizeTags} onChange={handleSizeTag} />
                    </div>
                    <div className="form-group">
                      <label className="control-label">Color:</label>
                      <TagsInput value={colortags} onChange={handleColorTag} />
                    </div>
                    <div className="form-group">
                      <label className="control-label">Material</label>
                      <TagsInput
                        value={materialTags}
                        onChange={handleMaterial}
                      />
                    </div>
                  </form>
                </div>
                <div className="col-lg-12 p-t-20">
                  <label className="mdl-textfield__label">Shipping Fee</label>
                  <div className="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width">
                    <input
                      defaultValue={productParams && productParams.shippingFee}
                      className="mdl-textfield__input"
                      type="number"
                      onChange={(e) => setShippingFee(e.target.value)}
                    />
                  </div>
                </div>
                <div className="col-lg-12 p-t-20">
                  <label className="mdl-textfield__label">
                    Product Pricing
                  </label>
                  <div className="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width">
                    <input
                      defaultValue={
                        productParams && productParams.amount
                          ? productParams.amount
                          : "Product Name"
                      }
                      className="mdl-textfield__input"
                      type="text"
                      onChange={(e) => setPrice(e.target.value)}
                    />
                  </div>
                </div>

                <div className="col-lg-12 p-t-20 text-center">
                  <button
                    type="button"
                    className="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect m-b-10 m-r-20 btn-pink"
                    onClick={handleSubmit}
                  >
                    Submit
                  </button>
                  <button
                    type="button"
                    className="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect m-b-10 btn-default"
                    onClick={handleCancel}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
