import axios from 'axios';

export const logIn = data => {
    return axios.post(`${process.env.REACT_APP_API_URL}/vendor/login`, data, {
        headers: {
            Authorization: localStorage.access_token
        }
    })
}  

export const signUp = data => {
    return axios.post(`${process.env.REACT_APP_API_URL}/vendor/sendRequest`, data, {
        headers: {
            Authorization: localStorage.access_token
        }
    }) 
}

export const forgetPassword = (data) => {
    return axios.post(`${process.env.REACT_APP_API_URL}/customer/forgot`, data);
  };