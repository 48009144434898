import React, { Fragment, useEffect, useState } from "react";
import swal from "sweetalert";
import { getAllCustomers } from "../../api/customer/CustomerProfile";
import { PaginationComponent } from "../common/Pagination";

const pageSize = 10;

export const CustomerList = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [current, setCurrent] = useState(1);
  const [totalPage, setTotalPage] = useState(0);
  const [minIndex, setMinIndex] = useState(0);
  const [maxIndex, setMaxIndex] = useState(0);

  useEffect(() => {
    setIsLoading(true);
    getAllCustomers().then((res) => {
      if (res.data.status == "success") {
        setTableData(res.data.customers);
        setIsLoading(false);
        setTotalPage(res.data && res.data.customers.length / pageSize);
        setMinIndex(0);
        setMaxIndex(pageSize);
      }
    });
  }, []);

  const handlePagination = (page) => {
    setCurrent(page);
    setMinIndex((page - 1) * pageSize);
    setMaxIndex(page * pageSize);
  };

  return (
    <Fragment>
      {isLoading && <div id="preloader"></div>}
      <div className="page-content-wrapper">
        <div className="page-content">
          <div className="page-bar">
            <div className="page-title-breadcrumb">
              <div className=" pull-left">
                <div className="page-title">All Customers</div>
              </div>
              <ol className="breadcrumb page-breadcrumb pull-right">
                <li>
                  <i className="fa fa-home"></i>&nbsp;
                  <a className="parent-item" href="index.html">
                    Home
                  </a>
                  &nbsp;<i className="fa fa-angle-right"></i>
                </li>
                <li>
                  <a className="parent-item" href="">
                    Customers
                  </a>
                  &nbsp;<i className="fa fa-angle-right"></i>
                </li>
                <li className="active">All Customers</li>
              </ol>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">
              <div className="card card-box">
                <div className="card-head">
                  <header>All Customers</header>
                  <div className="tools">
                    <a
                      className="fa fa-repeat btn-color box-refresh"
                      href="javascript:;"
                    ></a>
                    <a
                      className="t-collapse btn-color fa fa-chevron-down"
                      href="javascript:;"
                    ></a>
                    <a
                      className="t-close btn-color fa fa-times"
                      href="javascript:;"
                    ></a>
                  </div>
                </div>

                <div className="card-body">
                  <div className="row p-b-20">
                    <div className="col-md-6 col-sm-6 col-6"></div>
                    <div className="col-md-6 col-sm-6 col-6">
                      <div className="btn-group pull-right">
                        <a
                          className="btn deepPink-bgcolor  btn-outline dropdown-toggle"
                          data-toggle="dropdown"
                        >
                          Tools
                          <i className="fa fa-angle-down"></i>
                        </a>
                        <ul className="dropdown-menu pull-right">
                          <li>
                            <a href="javascript:;">
                              <i className="fa fa-print"></i> Print{" "}
                            </a>
                          </li>
                          <li>
                            <a href="javascript:;">
                              <i className="fa fa-file-pdf-o"></i> Save as PDF{" "}
                            </a>
                          </li>
                          <li>
                            <a href="javascript:;">
                              <i className="fa fa-file-excel-o"></i> Export to
                              Excel{" "}
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>

                    <div className="table-scrollable">
                      <table
                        className="table table-hover table-checkable order-column full-width"
                        id="example4"
                      >
                        <thead>
                          <tr>
                            <th className="center"> Id</th>
                            <th className="center"> Name </th>
                            <th className="center"> Image </th>
                            <th className="center"> Address </th>
                            <th className="center"> Phone Number </th>
                            <th className="center"> Email </th>
                            <th className="center"> Status </th>
                            {/* <th className="center"> Action </th> */}
                          </tr>
                        </thead>
                        <tbody>
                          {tableData?.map((item, key) => {
                            return (
                              <Fragment>
                                {key >= minIndex && key < maxIndex && (
                                  <tr className="odd gradeX">
                                    <td className="center">
                                      <span>{key + 1}</span>
                                    </td>
                                    <td className="center">
                                      <span>{item?.fullName}</span>
                                    </td>
                                    <td className="user-circle-img">
                                      <span>
                                        <img
                                          src={item?.profile_image_url}
                                          style={{
                                            width: "50px",
                                            height: "50px",
                                          }}
                                        />
                                      </span>
                                    </td>
                                    <td className="center">
                                      <span>{item?.addresses[0]}</span>
                                    </td>
                                    <td className="center">
                                      <span>{item?.phone_number}</span>
                                    </td>
                                    <td className="center">
                                      <span>{item?.email}</span>
                                    </td>
                                    <td className="center">
                                      <span>Active</span>
                                    </td>
                                  </tr>
                                )}
                              </Fragment>
                            );
                          })}
                        </tbody>
                      </table>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <PaginationComponent
                          pageSize={pageSize}
                          current={current}
                          total={tableData && tableData.length}
                          onChange={handlePagination}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
