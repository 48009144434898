import axios from 'axios';


export const getAllOrders = (params = {}) => {
    return axios.get(`${process.env.REACT_APP_API_URL}/vendor/orders`, {
        headers: {
            Authorization: localStorage.access_token
        }, params
    })
}

export const getOrder = id => {
    return axios.get(`${process.env.REACT_APP_API_URL}/vendor/orders/${id}`, {
        headers: {
            Authorization: localStorage.access_token
        }
    }) 
}

export const updateOrderStatus = (id, data) => {
    return axios.patch(`${process.env.REACT_APP_API_URL}/vendor/status/${id}`, data,{
        headers: {
            Authorization: localStorage.access_token
        }
    })
}

export const filteredOrders = (params = {}) => {
    return axios.get(`${process.env.REACT_APP_API_URL}/vendor/orders/filterSd`, {
        headers: {
          Authorization: localStorage.access_token,
        },
        params,
      });
}
