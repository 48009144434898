import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { createStore, applyMiddleware } from "redux";
import { Provider } from "react-redux";
import reduxThunk from "redux-thunk";
import rootReducer from "./redux/index";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import Login from "./pages/Login";
import Signup from "./pages/Signup";
import Dashboard from "./pages/Dashboard";
import UserProfile from "./pages/UserProfile";
import Products from "./pages/Products";
import AddNewProduct from "./pages/AddNewProduct";
import UpdateShipping from "./pages/UpdateShipping";
import ShippingMethod from "./pages/ShippingMethod";
import Orders from "./pages/Orders";
import UpdateOrders from "./pages/UpdateOrders";
import UpdateProducts from "./pages/UpdateProducts";
import UpdateOrder from "./pages/UpdateOrder";
import OrderDetailManagement from "./pages/OrderDetailManagement";
import { TestComponent } from "./components/auth/TestComponent";
import Customers from "./pages/Customers";
import Transactions from "./pages/Transactions";
import ForgetPassword from "./pages/ForgetPassword";

export const store = createStore(rootReducer, applyMiddleware(reduxThunk));

ReactDOM.render(
  <Provider store={store}>
    <App>
      <Router>
        <Switch>
          <React.Fragment>
            <Route exact path="/" component={Login} />
            <Route path='/sign-up' component={Signup} />
            <Route path="/dashboard" component={Dashboard} />
            <Route path="/user-profile" component={UserProfile} />
            <Route path="/products" component={Products} />
            <Route path="/add-product" component={AddNewProduct} />
            <Route path="/update-product" component={UpdateProducts} />
            <Route path="/update-shipping" component={UpdateShipping} />
            <Route path="/shipping-method" component={ShippingMethod} />
            <Route path="/orders" component={Orders} />
            <Route path="/update-orders" component={UpdateOrders} />
            <Route path="/update-order" component={UpdateOrder} />
            <Route path="/order-detail" component={OrderDetailManagement} />
            <Route path="/customers" component={Customers} />
            <Route path="/transactions" component={Transactions} />
            <Route path="/forget-password" component={ForgetPassword} />
            <Route path="/test" component={TestComponent} />
          </React.Fragment>
        </Switch>
      </Router>
    </App>
  </Provider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
